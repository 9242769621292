import { Component, Input, OnInit } from '@angular/core';
import { AlertController, IonItemSliding, ModalController, NavController } from '@ionic/angular';
import { AlertInput } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { getBundleProductsForProduct, getShoppingCart, store, updateOrderLineStockLabel } from '@springtree/eva-sdk-redux';
import { get, isEmpty, isEqual, isNil, sortBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, take } from 'rxjs/operators';
import { BundleProductsModalComponent, BundleProductsModalDismissData } from 'src/app/modals/bundle-products-modal/bundle-products.modal';
import { EvaToastController } from 'src/app/modules/eva-toast/eva-toast.controller';
import { EvaApplicationConfigProvider } from 'src/app/services/eva-application-config/eva-application-config';
import { EvaErrorGeneratorProvider } from 'src/app/services/eva-error-generator/eva-error-generator';
import { OrderLifecycleService } from 'src/app/services/order-lifecycle/order-lifecycle.service';
import { StockLabelProvider } from 'src/app/services/stock-label/stock-label.provider';
import { fadeInOut } from 'src/app/shared/animations';
import { BUNDLE_PRODUCT_TYPE, DISCOUNT_PRODUCT_CUSTOM_ORDERLINE_TYPE,
  GIFTWRAPPING_PRODUCT_CUSTOM_ORDERLINE_DESCRIPTION } from 'src/app/shared/constants';
import { EvaFeedback, IExtendedFeedback } from 'src/app/shared/decorators/eva-feedback';
import { ILoggable, Logger } from 'src/app/shared/decorators/logger';
import isNotNil from 'src/app/shared/operators/isNotNil';
import { BasketService } from '../../basket.service';
import { ICanDeleteLineAction, IChangeStockLabelAction, IGoProductBundleAction } from '../basket-list-item/basket-list-item.component';
import { IBasketListProductData } from '../basket-list-product/basket-list-product.component';

/** Contains the data that is needed to render both complex and simple cards. There is no inbetween. */
export interface IRenderedCartLine {
  simpleCard: IBasketListProductData;
  showQuantityControls: boolean;
  showStockLabel: boolean;
  creationTime: string;
  /**
   * If the `OrderLineTypes` is not normal, we will hide discounts and bundle buttons
   *
   * @see https://eva2015.atlassian.net/browse/OPTR-1867
   */
  showSwipeButtons: boolean;
}

/** swipe actions that are available on list items */
export interface IListItemSwipeActions {
  discountNavigation: boolean;
  stockLabelSelection: boolean;
  bundleNavigation: boolean;
}

export const ILineActionsTypes = {
  NONE: 0,
  RESERVE_LINE: 1,
  ORDER_LINE: 2,
  CARRY_OUT: 3, // also known as ShipLine
  DELIVERY: 4
}

@Logger('[basket-list-component]')
@Component({
  selector: 'eva-basket-list',
  templateUrl: './basket-list.component.html',
  styleUrls: ['./basket-list.component.scss'],
  animations: [fadeInOut]
})
export class BasketListComponent implements OnInit, ILoggable {

  @Input() listItemSwipeActions: IListItemSwipeActions = {
    bundleNavigation: false,
    discountNavigation: false,
    stockLabelSelection: false
  };

  @Input() shoppingCartUpdates$ = new BehaviorSubject({
    shouldUpdate: true,
  })

  public lineActionTypes = ILineActionsTypes;

  public showOrderLinesTypes: boolean = false;

  public shoppingCart$ = getShoppingCart.getState$().pipe(
    map(state => state.response),
    distinctUntilChanged((prev, next) => isEqual(prev, next))
  );

  public shoppingCartLines$ = this.shoppingCart$.pipe(
    map(response => response?.ShoppingCart?.Lines ?? [])
  );

  public showEmptyBasketIcon$ = this.shoppingCartLines$.pipe(
    map( unfilteredCartLines => isEmpty(unfilteredCartLines) )
  );

  public cartRequiredData$ = this.shoppingCart$.pipe(
    map( cart => cart?.AdditionalOrderData?.RequiredData),
    isNotNil()
  );

  public lineDiscountProductOptions$ = this.shoppingCart$.pipe(
    map( cart => cart?.AdditionalOrderData?.LineDiscountProductOptions || []),
  );

  public cartLines$: Observable<IRenderedCartLine[]> = combineLatest([this.shoppingCartLines$, this.cartRequiredData$, this.lineDiscountProductOptions$]).pipe(
    map(([lines, cartRequiredData, lineDiscountProductOptions]) => {
      const cartLines = sortBy(lines, ['CreationTime']).reverse();

      // Check if some order lines came with LineActionType:0
      // @see https://n6k.atlassian.net/browse/OPTR-19389
      this.checkCartLinesActionNone(cartLines);

      // Get the gwp / wgwp line
      const pickDiscountProductLines: EVA.Core.OrderLineDto[] = Object.entries(cartRequiredData.OrderLines).map(([orderLineId, orderLineRequirements]) => {
        const hasPickDiscountProductRequirement = orderLineRequirements.find(orderLineRequirement => orderLineRequirement.Name === 'PickDiscountProduct' );

        if (hasPickDiscountProductRequirement) {
          const pickDiscountProductLine = cartLines.find( orderLine => orderLine.ID === parseInt(orderLineId, 10) );

          return pickDiscountProductLine;
        }
      }).filter(line => !isNil(line))

      // Get the FREEPRODUCTSET line
      const freeDiscountProductLines: EVA.Core.OrderLineDto[] = lineDiscountProductOptions.map((discountProduct) => {
        const freeDiscountProductLine = cartLines.find( orderLine => orderLine.ID === discountProduct.OrderLineID );

        return freeDiscountProductLine;
      })

      .filter( orderLine => !isNil(orderLine) );

      // This array contains order line IDS that we won't show in the cart
      // @see https://n6k.atlassian.net/browse/OPTR-18577
      // The ticket above wants to display the discount products in the cart as well
      const orderLineIdsToExcludeFromCart = []

      pickDiscountProductLines.forEach(pickDiscountProductLine => {
        // Add the GWP order line IDs to the exclude Ids array
        //
        orderLineIdsToExcludeFromCart.push(pickDiscountProductLine.ID);
        if (!isNil(pickDiscountProductLine.ParentID)) {
          // We also exclude the ParentID which is the product corresponding to the GWP
          //
          orderLineIdsToExcludeFromCart.push(pickDiscountProductLine.ParentID);
        }
      });

      freeDiscountProductLines.forEach(lineDiscount => {
        // Add the FREEPRODUCTSET line IDs to the exclude Ids array
        //
        orderLineIdsToExcludeFromCart.push(lineDiscount.ID);
        if (!isNil(lineDiscount.ParentID)) {
          // We also exclude the ParentID which is the product corresponding to the FREEPRODUCTSET
          //
          orderLineIdsToExcludeFromCart.push(lineDiscount.ParentID);
        }
      })

      cartLines.forEach(line => {
        // Exclude GiftWrapping lines
        // https://n6k.atlassian.net/browse/OPTR-30790
        //
        if (line.Type === 9) {
          orderLineIdsToExcludeFromCart.push(line.ID);
        }
      })

      const cartLinesResult: IRenderedCartLine[] = cartLines
        .filter(cartLine => !orderLineIdsToExcludeFromCart.includes(cartLine.ID))
        .map((line, _index, linesInCart) => {
          const cartLine: IRenderedCartLine = this.createRenderCartLine(line);

          // This is for bundle products
          //
          if (!isNil(line.Product) && Boolean(BUNDLE_PRODUCT_TYPE & line.Product.Type)) {
            cartLine.simpleCard.productsInBundle = this.getProductsInBundle(line, linesInCart);
          }

          /** An orderline dto will have a discount if this array is not empty */
          const hasDiscount = !isEmpty(line.Discounts);
          // Sometimes a product will have a discount amount on it, in that case we want to find its potential child ( an orderline point to it via `ParentID`)
          //
          if ( !isNil(line.Product) && hasDiscount ) {
            const matchingDiscountLines = linesInCart.filter(innerLine => this.isDiscountLine(innerLine) && innerLine.ParentID === line.ID );

            if ( !isEmpty(matchingDiscountLines) ) {
              cartLine.simpleCard.childDiscounts = matchingDiscountLines.map(matchingDiscountLine => this.createSimpleCard(matchingDiscountLine));
            }
          }

          const data: [IRenderedCartLine, EVA.Core.OrderLineDto] = [cartLine, line];

          return data;
        })
        .filter(([_renderedLine, line]) => {
          // We want to take out any lines that have a `ProductBundleLineID` value
          //
          const hasProductBundleLineID = !isNil(line.ProductBundleLineID);

          const notPartOfBundle = hasProductBundleLineID === false;

          /** If a discount line has a ParentID we would like to hide it because it will be shown as a child of its parent */
          const isChildDiscountLine = this.isDiscountLine(line) && !isNil(line.ParentID);

          const showOrderLine = notPartOfBundle && !isChildDiscountLine;

          return showOrderLine;
        })
        .map(([renderedLine]) => renderedLine);

        // Check if all the lines are the same action type
        // @see https://n6k.atlassian.net/browse/OPTR-18349
        const linesToLook = cartLinesResult.filter(line => line.simpleCard.ProductID);
        const haveSingleLineType = linesToLook?.every(line =>
          line?.simpleCard?.LineActionType === cartLinesResult[0]?.simpleCard?.LineActionType
        );

        if(linesToLook.length > 1 && !haveSingleLineType) {
          this.showOrderLinesTypes = true;
        } else {
          this.showOrderLinesTypes = false;
        }

      return cartLinesResult;
    })
  );

  /**
   * Discount Order Line Type
   */
  public discountTypeLines$: Observable<IRenderedCartLine[]> = this.cartLines$.pipe(
    filter(lines => !isEmpty(lines) ),
    map(lines => {
      return lines.filter(line => line?.simpleCard?.isDiscount)
    })
  )

  /**
   * Services Line Type
   * @see https://n6k.atlassian.net/browse/OPTR-23845
   */
  public serviceTypeLines$: Observable<IRenderedCartLine[]> = this.cartLines$.pipe(
    filter(lines => !isEmpty(lines) ),
    map(lines => {
      return lines.filter(line => line?.simpleCard?.isServiceProduct)
    })
  )

  /**
   * Reserve Order Line Type
   */
  public cartReserveTypeLines$: Observable<IRenderedCartLine[]> = this.cartLines$.pipe(
    filter(lines => !isEmpty(lines) ),
    map(lines => {
      return lines.filter(line => line?.simpleCard?.LineActionType === this.lineActionTypes.RESERVE_LINE)
    })
  )

  /**
   * Line Type `Order`
   */
  public cartOrderTypeLines$: Observable<IRenderedCartLine[]> = this.cartLines$.pipe(
    filter(lines => !isEmpty(lines) ),
    map(lines => {
      return lines.filter(line => line?.simpleCard?.LineActionType === this.lineActionTypes.ORDER_LINE)
    })
  )

  /**
   * Carry-Out Order Line Type
   */
  public cartCarryOutTypeLines$: Observable<IRenderedCartLine[]> = this.cartLines$.pipe(
    filter(lines => !isEmpty(lines) ),
    map(lines => {
      return lines.filter(line => line?.simpleCard?.LineActionType === this.lineActionTypes.CARRY_OUT)
    })
  )

  /**
   * Delivery Order Line Type
   */
  public cartDeliveryTypeLines$: Observable<IRenderedCartLine[]> = this.cartLines$.pipe(
    filter(lines => !isEmpty(lines) ),
    map(lines => {
      return lines.filter(line => line?.simpleCard?.LineActionType === this.lineActionTypes.DELIVERY)
    })
  )

  public iconStream$ = this.$orderLifecycleService.pageIconStream$;
  public labelStream$ = this.$orderLifecycleService.pageTitleStream$;

  public hasPendingPayments = getShoppingCart.getResponse$().pipe(
    map(response => response?.ShoppingCart?.Lines?.length > 0 && response?.Amounts?.Paid?.Pending !== 0 && !response?.ShoppingCart?.IsPaid)
  );

  logger: Partial<Console>;

  public orderId$ = getShoppingCart.getResponse$().pipe(
    isNotNil(),
    map(cart => cart.ShoppingCart.ID)
  );

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private toastCtrl: EvaToastController,
    private $translate: TranslateService,
    private $evaErrorGenerator: EvaErrorGeneratorProvider,
    private $evaApplicationConfig: EvaApplicationConfigProvider,
    private $stockLabel: StockLabelProvider,
    private alertCtrl: AlertController,
    private $orderLifecycleService: OrderLifecycleService,
    private $basket: BasketService
  ) { }

  ngOnInit() {}

  public async canDeleteLine(event: ICanDeleteLineAction) {
    const { basketListData, itemSliding} = event;

    const hasPendingPayments = await this.hasPendingPayments.pipe(take(1)).toPromise();

    if (hasPendingPayments) {
      this.askConfirmationToChangeTheOrderOnPendingPayments(async () => this.deleteLine(basketListData, itemSliding));
      return;
    }

    await this.deleteLine(basketListData, itemSliding);
  }

  public async deleteLine(basketListData: IBasketListProductData, itemSliding?: IonItemSliding) {

    if (basketListData.isDiscount) {
      this.$basket.disableDiscount(basketListData.OrderLineID);
    } else {
      const orderLineId = basketListData.OrderLineID;
      this.cancelOrderLine(orderLineId,itemSliding);
    }
  }

  async cancelOrderLine(orderLineId: number, itemSliding?: IonItemSliding) {
    // https://n6k.atlassian.net/browse/OPTR-27782
    // used this approach to clear the basket
    // TODO: revert these changes when the BE will fix the issue for GiftWrapping
    //
    const lines = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response.ShoppingCart.Lines),
    ).toPromise();

    let giftWrappingLineId: number;
    const giftWrappingLine = lines.find(line => line.Type === EVA.Core.OrderLineTypes.GiftWrappingCosts);
    if (lines.length === 2 && !isNil(giftWrappingLine)) {
      giftWrappingLineId = giftWrappingLine.ID;
    }

    const request: EVA.Core.CancelOrder = { OrderLines: [{OrderLineID: orderLineId}] };
    if (!isNil(giftWrappingLineId)) {
      request.OrderLines.push({ OrderLineID: giftWrappingLineId });
    }

    this.logger.log('delete order line', orderLineId);

    const isOrderPaid: boolean = await getShoppingCart.getResponse$().pipe(
      first(), isNotNil(),
      map(response => response.ShoppingCart.IsPaid)
    ).toPromise();

    const hasCommittedLines: boolean = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response.ShoppingCart.Lines),
      map((lines) =>  lines.some((line) => line.QuantityCommitted > 0))
    ).toPromise();

    const hasReservedLines: boolean = await getShoppingCart.getResponse$().pipe(
      first(),
      map(response => response.ShoppingCart.Lines),
      map((lines) =>  lines.some((line) => line.QuantityReserved > 0))
    ).toPromise();

    if (hasReservedLines && isOrderPaid || hasCommittedLines) {
      const askConfirmation = await this.$basket.askConfirmationCancelLine();
      if(askConfirmation?.role !== 'confirm'){
        return;
      }
    }

    const {cartOperationPromise} = await this.$orderLifecycleService.cancelOrder({request});

    try {
      const cancelOrderResponse = await cartOperationPromise;

      // In case it fails we warn the user
      // @see https://n6k.atlassian.net/browse/OPTR-16898
      if (cancelOrderResponse.Result !== EVA.Core.OrderCancellationOptions.CanBeCancelled) {
        this.toastCtrl.showMessage(this.$translate.instant('order.line.cannot.be.deleted'));
        itemSliding?.close();
      }

    } catch (error) {
      this.toastCtrl.showMessage(this.$translate.instant('cancel.orderline.fail'));
      this.logger.error('orderline not removed', error);
    }

    return cartOperationPromise;
  }

  async openBundleProductsModal(productId: number) {
    const modal = await this.modalCtrl.create({
      component: BundleProductsModalComponent
    });

    try {
      await modal.present();

      modal.onDidDismiss<BundleProductsModalDismissData>().then(event => {
        const bundleId = event.data?.bundleId;
        if (!isNil(bundleId)) {
          this.navCtrl.navigateForward(`products/bundle-details/${bundleId}`, {
            queryParams: {productId}
          });
        }
      });
    } catch (e) {
      this.logger.error('error opening bundle products modal', e);
    }
  }

  async goToProductBundles(event: IGoProductBundleAction) {
    const { productId, showFeedback, itemSliding} = event;

    const [action, fetchPromise] = getBundleProductsForProduct.createFetchAction({
      ProductID: productId,
      IncludedFields: ['display_price', 'display_value', 'primary_image']
    });

    store.dispatch(action);

    try {
      const response = await fetchPromise;

      // We only want to open the modal if we have bundle result s
      //
      if (isEmpty(response.Result) === false) {
        this.openBundleProductsModal(productId);
      } else if (showFeedback) {
        this.toastCtrl.showMessage(
          this.$translate.instant('bundle.products.bundles.fetch.empty')
        );
      }

      if (itemSliding) {
        // We don't want the user to swipe the slider back
        itemSliding.close();
      }

    } catch (error) {
      // show error feedback
      //
      this.logger.error(error);

      this.$evaErrorGenerator.showTranslatedError(
        error,
        'bundle.products.bundles.fetch.fail'
      );
    }

    store.dispatch(action);
  }

  /**
   * Whenever the button is pressed, we will check which stock labels exist in the setting
   * `StockLabels:SellableSources`and we will show those to the user to choose from.
   * @see https://eva2015.atlassian.net/browse/OPTR-6971
   */
  async changeStockLabel(event: IChangeStockLabelAction) {
    const { simpleCardLine, itemSliding } = event;
    const sellableSources = await this.$evaApplicationConfig.sellableSources$.pipe(first()).toPromise();

    const stockLabels = await this.$stockLabel.getStockLabelsStream$().pipe(
      isNotNil(),
      first(),
      map(stockLabelResponse => {
        return stockLabelResponse.StockLabels.filter(stockLabel => sellableSources.includes(stockLabel.Name));
      })
    ).toPromise();

    const stockLabelsRadioButtons: AlertInput[] = stockLabels.map(stockLabel => {
      const alertInputOption: AlertInput = {
        // We have to cast this or else we will get typings errors..
        type: 'radio',
        label: stockLabel.Name,
        checked: stockLabel.ID === simpleCardLine.StockLabelID,
        value: `${stockLabel.ID}`
      };

      return alertInputOption;
    });

    const alertCtrl = await this.alertCtrl.create({
      header: this.$translate.instant('change.stock.label'),
      inputs: stockLabelsRadioButtons,
      buttons: [this.$translate.instant('action.save')]
    });

    alertCtrl.present();

    const dismissEvent = await alertCtrl.onDidDismiss<{values: string}>();

    const selectedStockLabelId = dismissEvent.data?.values;

    itemSliding.close();
    // If there was no stock label id selected, or its the same one that was already selected
    // we will return early
    //
    if (isNil(selectedStockLabelId) || (+ selectedStockLabelId) === simpleCardLine.StockLabelID) {
      return;
    }

    this.updateOrderLineStockLabel(simpleCardLine, selectedStockLabelId);
  }

  @EvaFeedback()
  private async updateOrderLineStockLabel(simpleCardLine: IBasketListProductData, selectedStockLabelId: string) {
    const orderId = await this.orderId$.pipe(first()).toPromise();

    const [action, updateOrderLineStockLabelPromise] = updateOrderLineStockLabel.createFetchAction({
      OrderID: orderId,
      OrderLineID: simpleCardLine.OrderLineID,
      StockLabelID: +selectedStockLabelId
    });

    store.dispatch(action);

    const feedback: IExtendedFeedback = {
      feedbackPromise: updateOrderLineStockLabelPromise,
      i18nFailParams: ['change.stock.label.fail', { productName: simpleCardLine.Description }],
      i18nSuccessParams: ['change.stock.label.success', { productName: simpleCardLine.Description }]
    };
    return feedback;
  }


  private createRenderCartLine(line: EVA.Core.OrderLineDto): IRenderedCartLine {

    return {
      simpleCard: this.createSimpleCard(line),
      creationTime: line.CreationTime,
      showQuantityControls: true,
      showSwipeButtons: line.Type === 0,
      showStockLabel: line.LineActionType === 3 // ShipLine
    };
  }

  /** Will return the products which are on a bundle based on the bundle product in the cart */
  private getProductsInBundle(bundleLine: EVA.Core.OrderLineDto, lines: EVA.Core.OrderLineDto[]): IBasketListProductData[] {
    // Every line with a parent ID of the bundle is part of it
    //
    const linesPartOfBundle = lines
      .filter(line => {
        const notDiscountLine = !this.isDiscountLine(line);

        return notDiscountLine;
      })
      .filter(line => line.ParentID === bundleLine.ID)
      .map(line => this.createRenderCartLine(line))
      .map(renderLine => renderLine.simpleCard);



    return linesPartOfBundle;
  }

  private createSimpleCard(line: EVA.Core.OrderLineDto): IBasketListProductData {
    const simpleCard: IBasketListProductData = {
      CustomID: get(line, 'Product.CustomID'),
      TotalQuantityToShip: line.TotalQuantityToShip,
      LineActionType: line.LineActionType,
      PriceInTax: line.TotalAmountInTax,
      Price: line.TotalAmount,
      Description: line.Description,
      CurrencyID: line.CurrencyID,
      OrderLineID: line.ID,
      ProductID: get(line, 'Product.ID'),
      CanModifyPrice: Boolean(line.Product?.Type & 64),
      HasRequirements: isEmpty(get(line, 'Product.Properties.product_requirements')) === false,
      SerialNumber: line.SerialNumber,
      QuantityShipped: line.QuantityShipped,
      ProductVariations: line.ProductVariation,
      StockLabelID: line.StockLabel,
      isDiscount: this.isDiscountLine(line),
      isServiceProduct: Boolean(line.Product?.Type & 16),
      discountId: line.DiscountID,
      displayProperties: line.DisplayProperties
    };

    return simpleCard;
  }

  private isDiscountLine(orderLine: EVA.Core.OrderLineDto) {
    const isDiscountLine = orderLine.Type === 1;

    return isDiscountLine;
  }

  private async askConfirmationToChangeTheOrderOnPendingPayments(confirmAction: () => Promise<void>) {
    const alert = await this.alertCtrl.create({
      header: this.$translate.instant('modify.order.pending.payments.order.warning.title'),
      message: this.$translate.instant('modify.order.pending.payments.order.warning.message'),
      buttons: [
        {
          text: this.$translate.instant('action.cancel'),
          role: 'cancel',
        },
        {
          text: this.$translate.instant('action.update'),
          cssClass: 'alert-danger',
          handler: async () => {
              await confirmAction();
            }
          }
        ]
      });

    alert.present();
  }

  /**
   * Some products (e.g. Bundles) came from BE as LineActionType:0
   * We need to give it a default value, otherwise will not be shown on basket list
   * so we get the lineActionType from one of his children
   * @see https://n6k.atlassian.net/browse/OPTR-19389
   */
  private checkCartLinesActionNone(cartLines){
    const linesToApplyCommit = [];
    cartLines.forEach(line => {
      if(line.LineActionType === this.lineActionTypes.NONE){
        line.LineActionType = this.lineActionTypes.DELIVERY;

        cartLines.forEach((cartLine,index) => {
          if(cartLine?.ParentID === line.ID){
            cartLines[index].LineActionType = this.lineActionTypes.DELIVERY;
            linesToApplyCommit.push(cartLine.ID);
          }
        });

        // const childFound = cartLines.find(cartLine => cartLine?.ParentID === line.ID);
        // if(!isNil(childFound?.LineActionType)){
        //   line.LineActionType = childFound.LineActionType || this.lineActionTypes.DELIVERY;
        // }
      }
    })

    if(linesToApplyCommit?.length){
      this.changeLineActionTypes(linesToApplyCommit);
    }
  }

  // This should update LineActionType on backend
  // @see https://n6k.atlassian.net/browse/OPTR-25553
  private async changeLineActionTypes(lines:number[]) {
    console.log('>>>>>> linesToApplyCommit: ',lines)
    const orderId = await this.orderId$.pipe(first()).toPromise();
    const request: EVA.Core.ChangeOrderLinesToDelivery = {
      OrderLineIDs: lines,
      OrderID: orderId
    };
    //this.$orderLifecycleService.changeOrderLinesToDelivery({request});
  }

}
