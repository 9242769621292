<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'deprecated.modal.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <p>{{ 'deprecated.modal.message' | translate }}</p>
</ion-content>
