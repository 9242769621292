import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { EvaToastController } from './modules/eva-toast/eva-toast.controller';
import { EvaToastModule } from './modules/eva-toast/eva-toast.module';
import { WebVitalsModule } from './modules/web-vitals/web-vitals.module';
import { WhatsNewModule } from './modules/whats-new/whats-new.module';
import { AppRoutingModule } from './routing/app-routing.module';
import { AppBlurProvider } from './services/app-blur/app-blur';
import { AudioFeedbackProvider } from './services/audio-feedback/audio-feedback';
import { AvailableSubscriptionsProvider } from './services/available-subscriptions/available-subscriptions.provider';
import { BasketTypeProvider } from './services/basket-type-provider/basket-type-provider';
import { BuildMetadataProvider } from './services/build-metadata/build-metadata';
import { ClientAppCommunicationProvider } from './services/client-app-communication/client-app-communication';
import { CommunicatorProvider } from './services/communicator/communicator';
import { CountrySettingsProvider } from './services/country-settings/country-settings';
import { CustomerUpdatedOrdersProvider } from './services/customer-updated-orders/customer-updated-orders';
import { CustomerUpsertNavigationProvider } from './services/customer-upsert-navigation/customer-upsert-navigation';
import { DebugAllowedProvider } from './services/debug-allowed/debug-allowed';
import { DecimalFormatService } from './services/decimal-format/decimal-format.service';
import { DiagnosticsProvider } from './services/diagnostics/diagnostics';
import { EnvironmentOverrideProvider } from './services/environment-override/environment-override';
import { EvaApplicationConfigProvider } from './services/eva-application-config/eva-application-config';
import { EvaAttachCustomerToOrderProvider } from './services/eva-attach-customer-to-order/eva-attach-customer-to-order';
import { EvaCheckoutProvider } from './services/eva-checkout/eva-checkout';
import { EvaCustomerSettingsProvider } from './services/eva-customer-settings/eva-customer-settings';
import { EvaErrorGeneratorProvider } from './services/eva-error-generator/eva-error-generator';
import { EvaHandleOrderScanProvider } from './services/eva-handle-order-scan/eva-handle-order-scan';
import { EvaInsufficientStockProvider } from './services/eva-insufficient-stock/eva-insufficient-stock';
import { EvaLoadingControllerProvider } from './services/eva-loading-controller/eva-loading-controller';
import { EvaLogoutProvider } from './services/eva-logout/eva-logout';
import {
  EvaParseBarcodeDeviceHubHandlerProvider
} from './services/eva-parse-barcode-device-hub-handler/eva-parse-barcode-device-hub-handler';
import { EvaParseBarcodeHandlerProvider } from './services/eva-parse-barcode-handler/eva-parse-barcode-handler';
import { EvaParseBarcodeSessionHandlerProvider } from './services/eva-parse-barcode-session-handler/eva-parse-barcode-session-handler';
import { EvaPaymentErrorHandlerProvider } from './services/eva-payment-error-handler/eva-payment-error-handler';
import { EvaPaymentMethodProvider } from './services/eva-payment-method/eva-payment-method';
import { EvaProductAddProvider } from './services/eva-product-add/eva-product-add';
import { EvaProductRequirementsProvider } from './services/eva-product-requirements/eva-product-requirements';
import { EvaProductStatusProvider } from './services/eva-product-status/eva-product-status';
import { EvaProductStockCheckProvider } from './services/eva-product-stock-check/eva-product-stock-check';
import { EvaSessionValidatorProvider } from './services/eva-session-validator/eva-session-validator';
import { EvaStartupProvider, startupProviderFactory } from './services/eva-start-up/eva-start-up';
import { StationSelectorProvider } from './services/eva-station-selector/station-selector.provider';
import { SummaryCheckoutProvider } from './services/eva-summary-checkout/summary-checkout.provider';
import { GetBundleProductProductMapProvider } from './services/get-bundle-product-product-map/get-bundle-product-product-map';
import { IncomingShipmentsProvider } from './services/incoming-shipments/incoming-shipments';
import { InterLogisticsSwitchProvider } from './services/inter-logistics-switch/inter-logistics-switch';
import { InternationalisationProvider } from './services/internationalisation/internationalisation';
import { MomentProvider } from './services/moment/moment';
import { OpenIdAuthProvider } from './services/open-id-auth/open-id-auth';
import { OrderAddressesProvider } from './services/order-addresses/order-addresses';
import { OverlayProvider } from './services/overlay/overlay';
import { PartialShipmentsProvider } from './services/partial-shipments/partial-shipments';
import { PickDiscountOptionRequirementsProvider } from './services/pick-discount-option-requirements/pick-discount-option-requirements';
import { PinProvider } from './services/pin/pin';
import { ProductBundlesProvider } from './services/product-bundle/product-bundles.provider';
import { ProductSearchFiltersProvider } from './services/product-search-filters/product-search-filters';
import { RoutesProvider } from './services/routes/routes';
import { ScanModeProvider } from './services/scan-mode/scan-mode-provider';
import { SelectedOrganisationUnitProvider } from './services/selected-organisation-unit/selected-organisation-unit';
import { SentinelProvider } from './services/sentinel/sentinel';
import { ServiceAvailabilityProvider } from './services/service-availability/service-availability';
import { ShipFromStoreProvider } from './services/ship-from-store/ship-from-store.service';
import { ShippingProvider } from './services/shipping/shipping';
import { ShopsProvider } from './services/shops/shops-provider';
import { StockLabelProvider } from './services/stock-label/stock-label.provider';
import { TaskSelectionProvider } from './services/task-selection/task-selection';
import { UserTaskProvider } from './services/user-task/user-task';
import { VerifyCustomerProvider } from './services/verify-customer/verify-customer';
import { DEFAULT_LOCALE } from './shared/modules/injection-tokens';
import { SharedModule } from './shared/shared.module';
import { ConfiguredTranslateModule } from './shared/translate';
import { FirebaseService } from './services/firebase/firebase.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    ReactiveFormsModule,
    ConfiguredTranslateModule,
    HttpClientModule,
    EvaToastModule,
    SharedModule,
    WebVitalsModule,
    WhatsNewModule.forRoot({contentFulWhatsNewContentType: 'companionAppWhatsNewCarrousel'}),
  ],
  providers: [
    AppVersion,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Keyboard,
    AppBlurProvider,
    { provide: DEFAULT_LOCALE, useValue: 'en-US' },
    Keyboard,
    EvaStartupProvider,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: startupProviderFactory,
      deps: [EvaStartupProvider]
    },
    AudioFeedbackProvider,
    OverlayProvider,
    EvaToastController,
    EvaErrorGeneratorProvider,
    EvaSessionValidatorProvider,
    EvaApplicationConfigProvider,
    EvaProductAddProvider,
    EvaProductStatusProvider,
    EvaInsufficientStockProvider,
    EvaAttachCustomerToOrderProvider,
    EvaProductRequirementsProvider,
    StationSelectorProvider,
    RoutesProvider,
    EvaHandleOrderScanProvider,
    EvaProductStockCheckProvider,
    InterLogisticsSwitchProvider,
    EvaPaymentMethodProvider,
    EvaCheckoutProvider,
    EvaCustomerSettingsProvider,
    EvaPaymentErrorHandlerProvider,
    EvaLoadingControllerProvider,
    DebugAllowedProvider,
    EvaLogoutProvider,
    DiagnosticsProvider,
    GetBundleProductProductMapProvider,
    BasketTypeProvider,
    CustomerUpsertNavigationProvider,
    ScanModeProvider,
    CommunicatorProvider,
    UserTaskProvider,
    ClientAppCommunicationProvider,
    EvaParseBarcodeHandlerProvider,
    EvaParseBarcodeDeviceHubHandlerProvider,
    EvaParseBarcodeSessionHandlerProvider,
    AppBlurProvider,
    InternationalisationProvider,
    ShippingProvider,
    IncomingShipmentsProvider,
    EnvironmentOverrideProvider,
    PartialShipmentsProvider,
    MomentProvider,
    CountrySettingsProvider,
    BuildMetadataProvider,
    ProductSearchFiltersProvider,
    ProductBundlesProvider,
    ShipFromStoreProvider,
    PinProvider,
    VerifyCustomerProvider,
    TaskSelectionProvider,
    SentinelProvider,
    CustomerUpdatedOrdersProvider,
    StockLabelProvider,
    OrderAddressesProvider,
    SentinelProvider,
    PickDiscountOptionRequirementsProvider,
    SummaryCheckoutProvider,
    SelectedOrganisationUnitProvider,
    OpenIdAuthProvider,
    AvailableSubscriptionsProvider,
    ServiceAvailabilityProvider,
    DecimalFormatService,
    ShopsProvider,
    FirebaseService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
