import { Injectable } from "@angular/core";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue, RemoteConfig } from "firebase/remote-config";
import * as firebaseConfig from "firebase-config.json";

/**
 * This service is responsible for fetching remote config from Firebase, and providing the values to the rest of the application.
 */
@Logger('[firebase-service]')
@Injectable({
  providedIn: 'root'
})
export class FirebaseService implements ILoggable {

  logger: Partial<Console>;

  private VAR_OPT_IN_NAMESPACE_LIST = "optInNamespaceList";
  private VAR_TIMEOUT_SECONDS = "timeoutSeconds";

  private optInNamespaceList?: Record<string, boolean>;
  private timeoutSeconds?: number
  private remoteConfig: RemoteConfig;

  constructor() {
    const app = initializeApp(firebaseConfig);

    // Initialize Remote Config and get a reference to the service
    this.remoteConfig = getRemoteConfig(app);

    // Create default config
    const defaultConfig = {};
    defaultConfig[this.VAR_OPT_IN_NAMESPACE_LIST] = {};
    defaultConfig[this.VAR_TIMEOUT_SECONDS ] = 60;
    this.remoteConfig.defaultConfig = defaultConfig;

    // Set minimum fetch interval (optional)
    this.remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // 1 hour

    this.reloadConfig();
  }

  // Reloads remote config
  public reloadConfig(): Promise<void> {
    return fetchAndActivate(this.remoteConfig).then(() => {
      const optInNamespaceListSource = getValue(this.remoteConfig, this.VAR_OPT_IN_NAMESPACE_LIST).asString();
      this.timeoutSeconds = getValue(this.remoteConfig, this.VAR_TIMEOUT_SECONDS).asNumber();
      if (optInNamespaceListSource) {
        this.optInNamespaceList = JSON.parse(optInNamespaceListSource);
      }
    }).catch((error) => {
      this.logger.error("Error fetching remote config:", error);
    });
  }

  public getTimeout(): number{
    return this.timeoutSeconds;
  }

  public isCustomerOptedInForWarning(namespace: string): boolean {
    return this.optInNamespaceList[namespace.toLocaleLowerCase()] || false;
  }
}
