import { Injectable } from '@angular/core';
import { getApplicationConfiguration, TAvailableGiftCardHandlers } from '@springtree/eva-sdk-redux';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { DiagnosticsProvider } from '../diagnostics/diagnostics';
import { TPaymentMethod } from '../eva-payment-method/eva-payment-method';

enum ReturnType {
  STRING,
  NUMBER,
  BOOLEAN,
  ARRAY_INT,
  ARRAY_STR
}

export interface ILiveGuardCloud {
  Available: boolean;
  Url: string;
  Namespace: string;
}

export interface IExtendedCustomFields {
  [key: string]: EVA.Core.CustomFieldResponse[];
}

export enum EScanTarget {
  PDP = 0,
  CART = 1
}

export enum ShippingOptions {
  Automatic = 0,
  Manual = 1,
  /**
   * This means users need to manually scan a shipment id and all the lines in the order will be shipped
   * @see https://eva2015.atlassian.net/browse/OPTR-702
   */
  ManualWithShipmentIDs = 2,
  /**
   * This means users need to manually scan shipment ids and product barcodes
   * @see https://eva2015.atlassian.net/browse/OPTR-704
   * */
  ManualWithDetailInspection = 3,
}
/** The value of the possible values of the 'App:Search:View' application configuration key */
export enum EAppSearchView {
  ListView = 0,
  CardView = 1
}

/**
 * Copy of `EVA.Core.AppBundlesBehavior`
 * @see https://eva2015.atlassian.net/browse/OPTR-1138
 */
export enum AppBundlesBehavior {
  Default = 0,
  Fashion = 1,
  Disabled = 2,
}


export interface IUserRequirements {
  [field: string]: EVA.Core.UserRequirementResponse;
}

type TUserTypes = EVA.Core.UserTypes;

export interface ApplicationSettingMetaData<D> {
  description: string;
  defaultValue?: D;
  ticket?: string | string[];
}

export interface ApplicationSetting<T> extends ApplicationSettingMetaData<any> {
  key: string;
  currentValue: Observable<T>;
}


export interface IOrganizationUnitCulture {
  CountryID: string;
  Culture: string;
  LanguageID: string;
}

export const giftCardTypes = [
  'GIFTCARD', // this is the new one
  'INTERSOLVE',
  'VALUTEC',
  'FASHIONCHEQUE',
  'ADYEN_CHECKOUT_API_GIFTCARD',
  'APIGIFTCARD'
] as const;

export type TGiftCardType = typeof giftCardTypes[number];

export const adyenPosTypes = [
  'ADYEN_POSSDK_CARDREADER',
  'ADYEN_POSSDK_TAPTOPAY'
];

export enum ScanditScannerCamera {
  rear = 'rear',
  front = 'front',
}

import UserAccountType = EVA.Core.UserAccountType;

/**
 * Because this application will behave very dynamically based on the `GetApplicationConfiguration` response, having a central place with all the
 * configuration properties we are interested in is useful
 */
@Injectable()
export class EvaApplicationConfigProvider {

  private applicationConfig$ = getApplicationConfiguration.getResponse$()
    .pipe(
      filter(response => Boolean(response) && Boolean(response.Configuration)),
      map(response => response.Configuration)
    );

  public assetBaseUrl$: Observable<string> = this.getSetting('Urls:Assets', {
    description: 'This setting dictates the base url for all EVA assets'
  });

  public liveGuardCloud$: Observable<ILiveGuardCloud> = this.getSetting('LiveGuardCloud', {
    description: 'This setting will provide the LiveGuardCloud metadata',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-33560'
  });

  public appStockLabelsAvailable$: Observable<number[]> = this.getSetting('App:StockLabels:Available', {
    description: 'This setting will dictates which stock labels should be shown on stock movements, cycle count, full stock count and inventory details screens',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-307',
    defaultValue: []
  }, ReturnType.ARRAY_INT);

  public returnableStockLabels$: Observable<string[]> = this.getSetting('StockLabels:Returnable', {
    description: 'This setting will dictates which stock labels should be shown on return order',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-13595',
    defaultValue: []
  });

  public searchDefaultMethod$: Observable<1 | 0> = this.getSetting('App:Search:DefaultMethod', {
    description: `This setting dectates what the default search should be
    Based on this we will perform the 'SearchProducts' on custom_id or perform a normal search`
  }, ReturnType.NUMBER);

  public scanditLicenseKey$: Observable<string> = this.getSetting('App:Scandit:LicenseKey', {
    description: 'The scandit license key. Replacement of the scandit app key. This one is used for multiple bundle Id\'s at once',
  }, ReturnType.STRING);

  public enableMatrix$: Observable<boolean> = this.getSetting('App:Scandit:EnableMatrix', {
    description: 'Whether we should display the matrix functionality or not',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-17456',
    defaultValue: false
  }, ReturnType.BOOLEAN);

  public showLegalText$: Observable<boolean> = this.getSetting('App:Rituals:HongKongText', {
    description: 'Whether we should display legal text or not',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-19692',
    defaultValue: false
  }, ReturnType.BOOLEAN);

  public extendedCustomFields$: Observable<IExtendedCustomFields> = this.getSetting('ExtendedCustomFields', {
    description: 'Use new custom fields location',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-20123',
  });

  public stockMutationReasonsAvailable$: Observable<number[]> = this.getSetting('App:StockMutationReasons:Available', {
    description: 'These are the stock mutation reasons we should be rendering, anything not in this list should be hidden'
  }, ReturnType.ARRAY_INT);

  public printInvoiceDefaultToggle$: Observable<boolean> = this.getSetting('App:Documents:DefaultPrintInvoice', {
    description: 'Default value of the print invoice toggle, which takes effect in produce documents modal in the ODP & checkout',
  }, ReturnType.BOOLEAN);

  public printReceiptDefaultToggle$: Observable<boolean> = this.getSetting('App:Documents:DefaultPrintReceipt', {
    description: 'Default value of the print receipt toggle, which takes effect in produce documents modal in the ODP & checkout'
  }, ReturnType.BOOLEAN);

  public printInvoiceShow$: Observable<boolean> = this.getSetting('App:Documents:ShowPrintInvoice', {
    description: 'Whether to show the print invoice toggle in the ODP & checkout produce documents modal'
  }, ReturnType.BOOLEAN);

  public scanTarget$: Observable<number> = this.getSetting('App:ScanTarget', {
    description: `Based on this setting we will either add an item to our shopping cart or go to its pdp in the search page
    where 0 means we need to go to the pdp and 1 means we need to add it to the cart`,
    defaultValue: 1
  }, ReturnType.NUMBER);

  public alwaysScanStation$: Observable<boolean> = this.getSetting('App:AlwaysScanStation', {
    description: 'Dictates whether the station needs to be chosen for each pin payment or not'
  }, ReturnType.BOOLEAN);

  public showProductStatus$: Observable<boolean> = this.getSetting('App:Product:ShowProductStatus', {
    description: 'Whether to show product status chips on the product (simple or complex) cards',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-2932']
  }, ReturnType.BOOLEAN);

  public interbranchShippingOption$: Observable<ShippingOptions> = this.getSetting('Interbranch:ShippingOption', {
    description: 'Shipping option for interbranch orders',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-702', 'https://eva2015.atlassian.net/browse/OPTR-704']
  });

  public shippingBackendIDAutoGenerated$: Observable<boolean> = this.getSetting('Orders:Shipment:AutoGenerateShipmentBackendIDForInterbranchOrders', {
    description: 'Shipping backend Id is auto generated, so the user does not have to scan it',
    ticket: ['https://n6k.atlassian.net/browse/OPTR-16132']
  });

  public alwaysReturnToSellable$: Observable<boolean> = this.getSetting('Orders:Returns:AlwaysReturnToSellable', {
    description: 'Check if we need to hide damaged and demo stock locations',
    ticket: ['https://n6k.atlassian.net/browse/OPTR-17372']
  });

  public ordersAllowMixed$: Observable<boolean> = this.getSetting('Orders:AllowMixed', {
    description: 'Check if we allow the user to create mixed orders or not',
    ticket: ['https://n6k.atlassian.net/browse/OPTR-18891']
  });

  public returnToSupplierShippingOption$: Observable<ShippingOptions> = this.getSetting('ReturnToSupplier:ShippingOption', {
    description: 'Shipping option for rma orders',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-702', 'https://eva2015.atlassian.net/browse/OPTR-704']
  });

  public allowChangeShippingMethod$: Observable<boolean> = this.getSetting('App:Checkout:AllowChangingShippingMethod', {
    description: 'Whether we allow change the shipping method in the UI (shipping tile in checkout page)',
    ticket: ['https://n6k.atlassian.net/browse/OPTR-16225']
  }, ReturnType.BOOLEAN);

  public loginWithIdentificationPinEnabled$: Observable<boolean> = this.getSetting('LoginWithIdentificationPin:Enabled', {
    description: 'Whether login with PIN functionality is availavble',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-3826'],
    defaultValue: false
  });

  public loginPrefferedMethod$: Observable<string> = this.getSetting('App:Login:PreferredMethod', {
    description: 'Preffered method to be selected when opening the login page',
    ticket: ['https://n6k.atlassian.net/browse/OPTR-24277'],
    defaultValue: 'email'
  });

  public appGiftWrappingEnabled$: Observable<boolean> = this.getSetting('App:GiftWrapping:Enabled', {
    description: 'This will dictate whether the application allows order giftwrapping',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-3873']
  }, ReturnType.BOOLEAN);

  public AppBundlesBehavior$: Observable<AppBundlesBehavior> = this.getSetting('App:Bundles:Behavior', {
    description: 'This will dictate what the default line action type is for newly added order lines',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-902', 'https://eva2015.atlassian.net/browse/OPTR-1138']
  }, ReturnType.NUMBER);

  /**
   * @see https://eva2015.atlassian.net/browse/OPTR-1610
   */
  public defaultLineActionType$: Observable<number> = this.getSetting('DefaultLineActionType', {
    // defaultValue: 3,
    description: 'This will dictate what the default line action type is for newly added order lines',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-902'
  });


  public appSearchView$: Observable<EAppSearchView> = this.getSetting('App:Search:View', {
    description: `This will determine what kind of element style we will render in our search results,
    The card view will be showing the image of the product in comparsion with the list view as of 18-09-2018`,
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-919'
  }, ReturnType.NUMBER);

  public productLogicalLevel$: Observable<string> = this.getSetting('App:Search:ProductLogicalLevel', {
    description: 'Determines which logical level to do a product search on',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-732'
  }, ReturnType.STRING);

  public lineActionTypesAvailable$: Observable<number[]> = this.getSetting('App:LineActionTypes:Available', {
    description: 'Determines which line action types the employee can use',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1044'
  }, ReturnType.ARRAY_INT);

  public onboardingQRUrl$: Observable<string> = this.getSetting('App:Loyalty:OnboardingQRUrl', {
    description: 'Determines if we should show the Customer Loyalty QR',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-20106'
  }, ReturnType.STRING);

  public lineActionTypesVisible$: Observable<boolean> = this.getSetting('App:LineActionTypes:Visible', {
    description: 'Determines if we should show the line action types kind in the application',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-4131'
  }, ReturnType.BOOLEAN);

  public wishListsEnabled$: Observable<boolean> = this.getSetting('App:WishLists:Enabled', {
    description: 'Determines whether wishlist functionality is enabled or not',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-1044', 'https://eva2015.atlassian.net/browse/OPTR-5185']
  }, ReturnType.BOOLEAN);

  public showCustomer$: Observable<boolean> = this.getSetting('App:Customer:Enabled', {
    description: 'Whether to show the customer attach button in the checkout or not',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1122',
    defaultValue: true
  }, ReturnType.BOOLEAN);

  public showConfigurableProductsView$: Observable<boolean> = this.getSetting('App:ConfigurableProduct:ShowView', {
    description: 'Whether to show the dimensional products view or not',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16514',
    defaultValue: false
  }, ReturnType.BOOLEAN);

  public showBarCodeForCustomer$: Observable<boolean> = this.getSetting('App:Customer:ShowBarcode', {
    description: 'Determines whether to show the customer barcode in the customer details page or not',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1548'
  }, ReturnType.BOOLEAN);

  public showCustomerShowAddresses$: Observable<boolean> = this.getSetting('App:Customer:ShowAddresses', {
    description: 'Determine whether to show addresses customer details page or not',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-2154'
  }, ReturnType.BOOLEAN);

  public clockWorkedHours$: Observable<boolean> = this.getSetting('Workforce:ClockWorkedHours', {
    description: `Checkes if clock in and our hours is enabled`,
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1241'
  });

  public userRequirements$: Observable<IUserRequirements> = this.getSetting('UserRequirements', {
    description: 'Which user fields are required or not, this takes effect both in edit and create user',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1272'
  });

  public ritualsLoyaltyEnabled$: Observable<boolean> = this.getSetting('Rituals:LoyaltyEnabled', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1610',
    description: 'Whether the rituals loyalty functionality is enabled or not'
  });

  public customerSSNEnabled$: Observable<boolean> = this.getSetting('App:Customer:UseSocialSecurityNumberLookup', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1672',
    description: 'Whether the social security number functionality is enabled or not'
  }, ReturnType.BOOLEAN);

  public customerEnabled$: Observable<boolean> = this.getSetting('App:Customer:Enabled', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1788',
    description: 'Whether CRM is enabled or not',
    defaultValue: true
  }, ReturnType.BOOLEAN);

  public cultures$: Observable<IOrganizationUnitCulture[]> = this.getSetting('Cultures', {
    description: 'Cultures that are displayed on the customer create/edit page',
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-3240']
  });

  public organizationUnitCulture$: Observable<IOrganizationUnitCulture> = this.getSetting('OrganizationUnitCulture', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-1905',
    description: 'We will use this to determine which language options to show, based on the CountryID',
    defaultValue: { CountryID: null, Culture: null, LanguageID: null }
  });

  public giftCardDefaultType$: Observable<TGiftCardType> = this.getSetting('App:GiftCard:DefaultType', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-2220',
    defaultValue: 'INTERSOLVE' as TGiftCardType,
    description: 'The default giftcard type, will be used to pre-select a giftcard handler in AvailableGiftCardHandlers'
  }, ReturnType.STRING);

  public contentfulAccessToken$: Observable<string> = this.getSetting('Contentful:AccessToken', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-2433',
    description: 'Information required to initialize the contenful SDK'
  });

  public contentfulSpaceId$: Observable<string> = this.getSetting('Contentful:SpaceID', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-2433',
    description: 'Information required to initialize the contenful SDK',
  });

  public orderShowVatNumber$: Observable<string> = this.getSetting('App:Order:ShowVatNumber', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-2435',
    description: 'Whether to show the vat number tile or not',
  }, ReturnType.BOOLEAN);

  public orderShowFiscalID$: Observable<boolean> = this.getSetting('App:Order:ShowFiscalID', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16268',
    description: 'Whether to show the Fiscal ID tile or not',
  }, ReturnType.BOOLEAN);

  public orderMaxLines$: Observable<number> = this.getSetting('App:Order:MaxLineCount', {
    description: 'Get max lines allowed to show for an order',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-20058',
    defaultValue: 50
  }, ReturnType.NUMBER);

  public checkoutShowSignOrder$: Observable<boolean> = this.getSetting('App:Checkout:ShowSignOrder', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-2553',
    description: 'Whether to show the signature button or not',
  }, ReturnType.BOOLEAN);

  public currencyId$: Observable<string> = this.getSetting('CurrencyID', {
    description: 'Will be used as fallback when no currencyID is available in the context of whatever is being viewed',
    defaultValue: 'EUR'
  });

  public runnerTasksEnabled$: Observable<boolean> = this.getSetting('App:Feature:StockReplenishmentTask:Enabled', {
    description: 'Whether the runner tasks functionality is enabled or not',
    defaultValue: true,
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-3185']
  }, ReturnType.BOOLEAN);

  public receiveGoodsEnabled$: Observable<boolean> = this.getSetting('ReceiveShipmentTasks:Enabled', {
    description: 'Whether the receive goods functionality is enabled or not',
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-3981',
    defaultValue: true
  });

  public showCustomerReference$: Observable<boolean> = this.getSetting('App:Order:ShowCustomerReference', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-3244',
    description: 'Determine whether to show the customer reference field when on delivery'
  }, ReturnType.BOOLEAN);

  public customerShowPreview$: Observable<boolean> = this.getSetting('App:Customer:ShowPreview', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-3446',
    description: 'Whether to show the customer info page after creation or not'
  }, ReturnType.BOOLEAN);

  public searchDefaultUserTypeOverride$: Observable<TUserTypes> = this.getSetting('App:Search:DefaultUserTypeOverride', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-3974',
    description: 'What to use as `UserTypeOverride` in the `searchProducts` service'
  }, ReturnType.NUMBER);

  public twoFactorAuthenticationSessionDuration$: Observable<number> = this.getSetting('SessionDuration:TwoFactorAuthentication', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-20278',
    description: 'Session duration for 2FAuthentication'
  }, ReturnType.NUMBER);

  public supplyAndDemandEnabled$: Observable<boolean> = this.getSetting('SupplyAndDemand:Enabled', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-3780',
    description: 'Whether to show extra information in the order details page or not',
    defaultValue: false
  });

  public addressShowAddress2LineCountries$: Observable<string[]> = this.getSetting('Addresses:Address2VisibleCountries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-15003',
    description: 'Will be used to determine whether we show a Address2 line or not, will be an array of countries',
    defaultValue: []
  });

  public addressHouseNumberVisibleCountries$: Observable<string[]> = this.getSetting('Addresses:HouseNumberVisibleCountries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-15003',
    description: 'Will be used to determine whether we show a house number or not, will be an array of countries',
    defaultValue: []
  });

  public addressHouseNumberRequiredCountries$: Observable<string[]> = this.getSetting('Addresses:HouseNumberRequiredCountries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-15003',
    description: 'Will be used to determine whether we show a house number or not, will be an array of countries',
    defaultValue: []
  });

  public addressStateVisibleCountries$: Observable<string[]> = this.getSetting('Addresses:StateVisibleCountries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16748',
    description: 'Will be used to determine whether we show a state or not, will be an array of countries',
    defaultValue: []
  });

  public addressCityOptionalCountries$: Observable<string[]> = this.getSetting('Addresses:CityOptionalCountries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16748',
    description: 'Will be used to determine whether we require a city or not, will be an array of countries',
    defaultValue: []
  });

  public addressStateLength2Countries$: Observable<string[]> = this.getSetting('Addresses:StateLength2Countries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16748',
    description: 'Will be used to determine whether we validate state by length 2, will be an array of countries',
    defaultValue: []
  });

  public addressStateLength3Countries$: Observable<string[]> = this.getSetting('Addresses:StateLength3Countries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16748',
    description: 'Will be used to determine whether we validate state by length 3, will be an array of countries',
    defaultValue: []
  });

  public addressZipCodeOptionalCountries$: Observable<string[]> = this.getSetting('Addresses:ZipCodeOptionalCountries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16748',
    description: 'Will be used to determine whether we require a zipcode or not, will be an array of countries',
    defaultValue: []
  });

  public address1OptionalCountries$: Observable<string[]> = this.getSetting('Addresses:Address1OptionalCountries', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16748',
    description: 'Will be used to determine whether we require address1 or not, will be an array of countries',
    defaultValue: []
  });

  public newSubscriptionsEnabled: Observable<boolean> = this.getSetting('App:Subscriptions:Enabled', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-4424',
    description: 'Whether to use new subscriptions services or not'
  }, ReturnType.BOOLEAN);

  public auditingProvider$: Observable<string> = this.getSetting('Auditing:Provider', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-4611',
    description: 'Value of auditing provider.If SAFT, will be used to determine whether we print the receipt automatically'
  });

  public defaultEmailInvoice$: Observable<boolean> = this.getSetting('App:Documents:DefaultEmailInvoice', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-5327',
    defaultValue: false,
    description: 'Determines the default value of the email checkbox in the print order pop up ( in the checkout )'
  }, ReturnType.BOOLEAN);

  public showEstimatedDeliveryTime$: Observable<boolean> = this.getSetting('App:Order:ShowEstimatedDeliveryTime', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-5542', 'https://eva2015.atlassian.net/browse/OPTR-4802'],
    defaultValue: false,
    description: 'Determines whether we render the delivery proposition card or not'
  }, ReturnType.BOOLEAN);

  public searchProductProperties$: Observable<string[]> = this.getSetting('App:Search:ProductProperties', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-2965'],
    description: 'What product properties to filter on',
    defaultValue: []
  }, ReturnType.ARRAY_STR);

  public fullStockCountDefaultRecountInterval$: Observable<number> = this.getSetting('UserTasks:FullStockCount:DefaultRecountInterval', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-6680',
    description: 'The default recount interval, will be used as default value for an input field in the full stock count create page. Value will be the denominator of a fraction where the numerator is 1. So 25% would be represented as 4',
  });

  public sellableSources$: Observable<string[]> = this.getSetting('StockLabels:SellableSources', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-6971'],
    description: 'contains which stock labels we can sell from',
    defaultValue: []
  }, ReturnType.ARRAY_STR);

  public suggestUpdateAddressOnOpenOrders$: Observable<boolean> = this.getSetting('App:Customer:SuggestUpdateAddressOnOpenOrders', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-6974'],
    description: 'Whether we open up a special page to suggest the user to update open orders',
    defaultValue: false
  }, ReturnType.BOOLEAN);

  public cycleCountRequireAllZonesToBeCounted$: Observable<boolean> = this.getSetting('App:CycleCount:RequireAllZonesToBeCounted', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-6205'],
    description: 'Whether all zones need to be counted in order to complete a zoned cycle count',
    defaultValue: true
  }, ReturnType.BOOLEAN);

  public taxExemptionEnabled$: Observable<boolean> = this.getSetting('Orders:TaxExemptionEnabled', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-7036'],
    description: 'Will be used to determine if we show tax exemption button on order or not',
    defaultValue: false
  });

  public showPrintGiftReceipt$: Observable<boolean> = this.getSetting('App:Documents:ShowPrintGiftReceipt', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-5407'],
    description: 'Will be used to determine if we show print gift receipt or not',
    defaultValue: false
  }, ReturnType.BOOLEAN);

  public showPrintInterbranchReceipt$: Observable<boolean> = this.getSetting('App:Documents:ShowPrintInterbranchOrderReceipt', {
    ticket: ['https://n6k.atlassian.net/browse/OPTR-17498'],
    description: 'Will be used to determine if we show print INTERBRANCH receipt or not',
    defaultValue: false
  }, ReturnType.BOOLEAN);

  public allowSoldByOverride$: Observable<boolean> = this.getSetting('App:AllowSoldByOverride', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-7937',
    defaultValue: false,
    description: 'determines whether we show the sold by tile or not'
  }, ReturnType.BOOLEAN);

  public showCompany$: Observable<boolean> = this.getSetting('App:Customer:ShowCompany', {
    ticket: ['https://eva2015.atlassian.net/browse/OPTR-7935'],
    defaultValue: false,
    description: 'Determines whether we show the company fields in customer create/edit'
  }, ReturnType.BOOLEAN);

  public showStationSelectorOnLogin$: Observable<boolean> = this.getSetting('App:ShowStationSelectorOnLogin', {
    ticket: 'https://eva2015.atlassian.net/browse/OPTR-8450',
    defaultValue: false,
    description: 'Whether to show the station selector upon login or not'
  }, ReturnType.BOOLEAN);

  public elevatedFunctionalityProvider$: Observable<string> = this.getSetting('Security:ElevatedFunctionalityProvider', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-8102',
    description: 'The elevated functionality provider'
  });

  public shippingProductSearchTemplateID$: Observable<number> = this.getSetting('App:Shipping:ProductSearchTemplateID', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-8915',
    description: 'Based on this, we will show / hide the quick bag select option in the SFS ship page'
  }, ReturnType.NUMBER);

  public fastReservationEnabled$: Observable<boolean> = this.getSetting('App:Checkout:FastReservationEnabled', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-9059',
    defaultValue: false,
    description: 'Based on this setting, we will be handling pick up orders (c&c) differently.'
  }, ReturnType.BOOLEAN);

  public shipFromStoreAllowPartialPick$: Observable<boolean> = this.getSetting('App:ShipFromStore:AllowPartialPick', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-9412',
    defaultValue: false,
    description: 'Setting for handling Interbranch order tasks.'
  }, ReturnType.BOOLEAN);

  public shipmentsAllowRemoteManualReceive$: Observable<boolean> = this.getSetting('App:Shipments:AllowRemoteManualReceive', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-9412',
    defaultValue: false,
    description: 'Allows receive goods tasks to be confirmed manually.'
  }, ReturnType.BOOLEAN);

  public featureLockOnOrganizationUnitEnabled$: Observable<boolean> = this.getSetting('App:Feature:LockOnOrganizationUnit:Enabled', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-9714',
    defaultValue: false,
    description: 'Whether to do organisation unit locking'
  }, ReturnType.BOOLEAN);

  public shipFromStorePaymentMethods$: Observable<TPaymentMethod[]> = this.getSetting('App:ShipFromStore:PaymentMethods', {
    defaultValue: ['CASH', 'EVAPAY'] as TPaymentMethod[],
    description: 'What payment methods the ship from store deliver step supports',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-9989'
  }, ReturnType.ARRAY_STR);

  public loginWithOpenIdEnabled$: Observable<boolean> = this.getSetting('OpenID:Enabled', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10302',
    defaultValue: false,
    description: 'Whether login with OpenId is availavble'
  });

  public openIdBaseUrl$: Observable<string> = this.getSetting('OpenID:BaseUrl', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10302',
    description: 'Setting for base url'
  });

  public openIdClientId$: Observable<string> = this.getSetting('OpenID:ClientID', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10302',
    description: 'Setting for base url'
  });

  public openIdAdditionalProviders$: Observable<boolean> = this.getSetting('OpenID:AdditionalProviders', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10329',
    description: 'Whether the openID configuration has multiple providers or not',
    defaultValue: false,
  });

  public openIdProviderID$: Observable<number> = this.getSetting('OpenID:ID', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10329',
    description: 'OpenID provider ID',
  });

  public openIdProviderName$: Observable<string> = this.getSetting('OpenID:Name', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10329',
    description: 'OpenID provider name',
  });

  public openIdUserType$: Observable<EVA.Core.UserTypes> = this.getSetting('OpenID:UserType', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10329',
    description: 'OpenID user type',
  });

  public supportMultipleShipments$: Observable<boolean> = this.getSetting('App:ShipFromStore:SupportMultipleShipments', {
    defaultValue: false,
    description: 'Whether the ship from store flow supports multiple shipments or not',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10347'
  }, ReturnType.BOOLEAN);

  public shipFromStoreProductProperties$: Observable<string[]> = this.getSetting('App:ShipFromStore:ProductPropertiesToShow', {
    defaultValue: [],
    description: 'Display product properties on product variantions',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-10188'
  }, ReturnType.ARRAY_STR);

  public stockReservationTaskAvailableActions$: Observable<number> = this.getSetting('App:StockReservationTask:AvailableActions', {
    defaultValue: null,
    description: 'Containing a bitwise enum, of which each digit represents an action that is not available',
  }, ReturnType.NUMBER);

  public shipFromStoreShowNetPrices$: Observable<boolean> = this.getSetting('App:ShipFromStore:ShowNetPrices', {
    defaultValue: false,
    description: 'Decides what price we will show in the ship from store flow',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-11145'
  }, ReturnType.BOOLEAN);


  public returnToSupplierShipmentCanHaveTrackingCode$: Observable<boolean> = this.getSetting('App:ReturnToSupplier:ShipmentCanHaveTrackingCode', {
    defaultValue: false,
    ticket: 'https://n6k.atlassian.net/browse/OPTR-11403',
    description: 'Will determine whether tracking code scanning is relevant or not'
  }, ReturnType.BOOLEAN);

  public printPriceLabelRequired$: Observable<boolean> = this.getSetting('App:PrintPriceLabelTask:PrintPriceLabelRequired', {
    defaultValue: false,
    ticket: 'https://n6k.atlassian.net/browse/OPTR-12447',
    description: 'Display price label printing in store'
  }, ReturnType.BOOLEAN);

  public printReceiptAfterCompletion$: Observable<boolean> = this.getSetting('App:ShipFromStore:PrintReceiptAfterCompletion', {
    defaultValue: true,
    ticket: 'https://n6k.atlassian.net/browse/OPTR-12660',
    description: 'Whether the SFS flow will call ProduceDocuments or not after completing the task'
  }, ReturnType.BOOLEAN);

  public includeCustomers$: Observable<boolean> = this.getSetting('App:SearchUsers:IncludeCustomers', {
    defaultValue: false,
    ticket: 'https://n6k.atlassian.net/browse/OPTR-13362',
    description: 'Whether the user configurations allows to search by customer'
  }, ReturnType.BOOLEAN);

  public includeEmployees$: Observable<boolean> = this.getSetting('App:SearchUsers:IncludeEmployees', {
    defaultValue: false,
    ticket: 'https://n6k.atlassian.net/browse/OPTR-13362',
    description: 'Whether the user configurations allows to search by employee'
  }, ReturnType.BOOLEAN);

  public movableStockLabels$: Observable<string[]> = this.getSetting('StockLabels:Movable', {
    defaultValue: [],
    description: 'List of movable stock labels',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-14482'
  });

  public adjustableStockLabels$: Observable<string[]> = this.getSetting('StockLabels:Adjustable', {
    defaultValue: [],
    description: 'List of adjustable stock labels',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-14482'
  });

  public giftCardHandlers$: Observable<TAvailableGiftCardHandlers> = this.getSetting('AvailableGiftCardHandlers', {
    defaultValue: [],
    ticket: 'https://n6k.atlassian.net/browse/OPTR-14043',
    description: 'List of gift card handlers'
  });

  public giftCardConfigurations$: Observable<EVA.Core.Management.ListGiftCardConfigurationsResponse.GiftCardConfigurationDto[]> = this.getSetting('AvailableGiftCardConfigurations', {
    defaultValue: [],
    ticket: `https://n6k.atlassian.net/browse/OPTR-17709`,
    description: 'List of available gift card configurations'
  });

  public fiscalConfigurationRequired$: Observable<boolean> = this.getSetting('Auditing:FiscalConfigurationRequired', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-13906',
    defaultValue: false,
    description: 'Will determine whether the fiscal data is required or not'
  });

  public preferredPriceDisplayMode$: Observable<EVA.Core.OrderPreferredPriceDisplayMode> = this.getSetting('PreferredPriceDisplayMode', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-15725',
    defaultValue: EVA.Core.OrderPreferredPriceDisplayMode.InTax,
    description: 'This will be used for determining whether or not to show prices including / excluding tax'
  });

  public newDiscountEngineEnabled$: Observable<boolean> = this.getSetting('EVA:NewDiscountEngineEnabled', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-15332',
    defaultValue: false,
    description: 'Will determine whether the new discount engine is enabled or not'
  });

  public printPriceLabelTaskEnabled$: Observable<boolean> = this.getSetting('App:PrintPriceLabelTask:Enabled', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-15892',
    defaultValue: true,
    description: 'Display print price label menu item'
  }, ReturnType.BOOLEAN);

  public defaultShopProximityRange$: Observable<number> = this.getSetting('App:DefaultShopProximityRange', {
    description: 'Default shop proximity range, will used for finding shops nearby',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-16616'
  }, ReturnType.NUMBER);

  public showCustomOrderFields$: Observable<string[]> = this.getSetting('App:Order:ShowCustomFields', {
    description: 'Order custom fields to create tiles for',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-17267',
    defaultValue: [],
  }, ReturnType.ARRAY_STR);

  public showCustomFieldOnODP$: Observable<string[]> = this.getSetting('App:Order:DisplayUserCustomFields', {
    description: 'Determine if shows user custom field -> Customer backend Id on ODP',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-22657',
    defaultValue: [],
  }, ReturnType.ARRAY_STR);

  public showTakeorverOrderAlert$: Observable<boolean> = this.getSetting('App:Order:ShowTakeoverAlert', {
    description: 'Determine if shows to the user an alert of someone else changing the order',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-22729',
    defaultValue: true,
  }, ReturnType.BOOLEAN);

  public ignoreTakeoverUsers$: Observable<string[]> = this.getSetting('App:Order:IgnoreTakeoverUpdateUsers', {
    description: 'When incoming users made the order update, we dont show the alert',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-22729',
    defaultValue: [],
  }, ReturnType.ARRAY_STR);

  public displayEcoTaxInfo$: Observable<boolean> = this.getSetting('Orders:Display:ShowEcoTax', {
    description: 'Determine if we should show Eco tax info',
    ticket: 'https://n6k.atlassian.net/browse/OPTR-23021',
  }, ReturnType.BOOLEAN);

  public customerBlockChangeEmailAddress$: Observable<boolean> = this.getSetting('Customer:BlockChangeEmailAddress', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-17749',
    defaultValue: false,
    description: 'Disable email field on edit customer'
  }, ReturnType.BOOLEAN);

  public appScannerProvider$: Observable<string> = this.getSetting('App:Scanner:Provider', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-21869',
    defaultValue: 'Scandit',
    description: 'Check if we should display Apple or Scandit scanner'
  },ReturnType.STRING);

  public ordersReturnToSupplierAllowEaches$: Observable<boolean> = this.getSetting('Orders:ReturnToSupplier:AllowEaches', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-18947',
    defaultValue: true,
    description: 'Allow returning individual products to supplier',
  }, ReturnType.BOOLEAN)

  public customerAccountType$: Observable<UserAccountType> = this.getSetting('App:Customer:AccountType', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-21880',
    description: 'Send AccountType to CreateCustomer service',
    defaultValue: UserAccountType.Basic
  });

  public hideShowMoreTile$: Observable<boolean> = this.getSetting('App:Checkout:HideShowMore', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-22134',
    description: 'Hide show more tile',
    defaultValue: false
  }, ReturnType.BOOLEAN);

  public isGWPScannerActive$: Observable<boolean> = this.getSetting('App:Basket:ScanGift', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-22194',
    defaultValue: false,
    description: 'Display the scanner for GWP'
  }, ReturnType.BOOLEAN);

  public hideRelations$: Observable<string[]> = this.getSetting('App:Product:HideRelations', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-22358',
    defaultValue: [],
    description: 'Hide related products'
  }, ReturnType.ARRAY_STR);

  public configurableProductFilterProperty$: Observable<string> = this.getSetting('App:ConfigurableProduct:FilterProperty', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-24225',
    description: 'Dictates what PIM property to filter on for configurable products',
    defaultValue: null,
  }, ReturnType.STRING);

  public configurableProductFilterValues$: Observable<string[]> = this.getSetting('App:ConfigurableProduct:FilterValues', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-24225',
    description: 'Provides a comma seperated list of all the values to filter on for configurable products',
    defaultValue: [],
  }, ReturnType.ARRAY_STR);

  public configurableProductFilterDefault$: Observable<string> = this.getSetting('App:ConfigurableProduct:FilterDefault', {
    ticket: 'https://n6k.atlassian.net/browse/OPTR-24225',
    description: 'Dictates what the default value to filter on for configurable products',
    defaultValue: null,
  }, ReturnType.STRING);

  constructor(private $diagnostics: DiagnosticsProvider) { }

  public getSetting<T>(applicationConfigKey: string, options: ApplicationSettingMetaData<T>, returnType?: ReturnType): Observable<T> {

    const originalValue = this.applicationConfig$.pipe(
      pluck(applicationConfigKey)
    );

    this.$diagnostics.saveApplicationConfigurationKey({
      ...options,
      currentValue: originalValue,
      key: applicationConfigKey
    });

    const valueWithFallBack = originalValue.pipe(
      map((applicationConfigKeyValue: T) =>
        isNil(applicationConfigKeyValue) ? options.defaultValue : this.checkKeyValue(applicationConfigKey, applicationConfigKeyValue, returnType)
      )
    );

    return valueWithFallBack;
  }

  /**
   * All seetings starting with 'App:', has been changed to type STRING from BE
   * so we need to parse every value to the expected returned value type.
   * @see https://n6k.atlassian.net/browse/OPTR-17421
   */
  private checkKeyValue(appConfigKey, appKeyValue, returnType?: ReturnType) {
    if (appConfigKey.includes('App:') && !isNil(returnType)) {

      // We check if the value its already typed, in that case return directly
      if (typeof appKeyValue === 'boolean' || typeof appKeyValue === 'number' || appKeyValue instanceof Array) {
        return appKeyValue;
      }

      if (returnType === ReturnType.NUMBER) {
        return parseInt(appKeyValue);
      }
      else if (returnType === ReturnType.BOOLEAN) {
        return (appKeyValue)?.toLowerCase() === 'true';
      }
      else if (returnType === ReturnType.ARRAY_INT) {
        return (appKeyValue)?.split(',').map(num => parseInt(num));
      }
      else if (returnType === ReturnType.ARRAY_STR) {
        return (appKeyValue)?.split(',')?.map(value => value?.trim());
      }
    }

    return appKeyValue;
  }

}
