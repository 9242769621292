import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from "../components/components.module";
import { FusejsModule } from "../modules/angular-fusejs";
import { PipesModule } from "../pipes/pipes.module";
import { ConfigPickerModalComponent } from "./config-picker-modal/config-picker-modal.component";
import { CreateShipmentFeedbackModalComponent } from "./create-shipment-feedback/create-shipment-feedback.modal";
import { CustomerSearchModalComponent } from "./customer-search-modal/customer-search.modal";
import { EditPinModalComponent } from "./edit-pin/edit-pin-modal";
import { TaskFilterModalComponent } from "./eva-task-filter-modal/eva-task-filter-modal";
import { InsufficientStockModalComponent } from "./insufficient-stock-modal/insufficient-stock-modal";
import { ItemSearchComponent } from "./item-search/item-search";
import { OrderLineDeliveryInfoModalComponent } from "./order-line-delivery-info-modal/order-line-delivery-info.modal";
import { RitualsPrintTalismanModalComponent } from "./rituals-print-talisman-modal/rituals-print-talisman.modal";
import { SelectStationModalComponent } from "./select-station-modal/select-station-modal";
import { DirectivesModule } from "../directives/directives.module";
import { RunnerTaskBlockedProductsModalComponent } from "./runner-task-blocked-products-modal/runner-task-blocked-products.modal";
import { CustomerReferenceModalComponent } from "./customer-reference-modal/customer-reference.modal";
import { OrderRemarkModalComponent } from "./order-remark-modal/order-remark-modal";
import { SharePaymentModalComponent } from "./share-payment-modal/share-payment.modal";
import { QuickBuyModalComponent } from "./quick-buy-modal/quick-buy.modal";
import { VatNumberModalComponent } from "./vat-number-modal/vat-number.modal";
import { ProductDiscountOptionsModalComponent } from "./product-discount-options-modal/product-discount-options.modal";
import { SummaryCheckoutModalComponent } from "./summary-checkout-modal/summary-checkout.modal";
import { ShippingRestrictionsModalComponent } from "./shipping-restrictions-modal/shipping-restrictions.modal";
import { CashDrawerModalComponent } from "./cash-drawer-modal/cash-drawer.modal";
import { RefundTransactionModalComponent } from "./refund-transaction-modal/refund-transaction.modal";
import { TaxExemptionModalComponent } from "./tax-exemption-modal/tax-exemption.modal";
import { ValMessagesModule } from "../shared/ngx-messages/val-messages.module";
import { OrderSetSoldByModalComponent } from "./order-set-sold-by-modal/order-set-sold-by.modal";
import { ErrorViewerModalComponent } from "./error-viewer-modal/error-viewer.modal";
import { PhoneNumberModalComponent } from "./phone-number-modal/phone-number-modal.modal";
import { DeliveryRestrictionsModalComponent } from "./delivery-restrictions-modal/delivery-restrictions.modal";
import { ModifyPriceModalComponent } from "./modify-price-modal/modify-price.modal";
import { PauseOrderModalComponent } from "./pause-order-modal/pause-order.modal";
import { ReturnOrdersModalComponent } from "./return-orders-modal/return-orders.modal";
import { BundleProductsModalComponent } from "./bundle-products-modal/bundle-products.modal";
import { ShippingMethodModalComponent } from "./shipping-method-modal/shipping-method.modal";
import { EvaOrderlineRequirementModalComponent } from "./orderline-requirement-modal/orderline-requirement.modal";
import { GenerateXReportModalComponent } from "./generate-x-report-modal/generate-x-report.modal";
import { GiftCardModalComponent } from "../components/gift-card-modal/gift-card.modal";
import { PaymentTimeoutModalComponent } from "./payment-timeout-modal/payment-timeout.modal";
import { IonInputFocusFixModule } from "../modules/ion-input-focus-fix/ion-input-focus-fix.module";
import { GiftReceiptModalComponent } from "./gift-receipt-modal/gift-receipt.modal";
import { BorrowedFilterModalComponent } from "./borrowed-filter-modal/borrowed-filter-modal";
import { ReturnDamagedModalComponent } from "./return-damaged-modal/return-damaged.modal";
import { UserFilterModalComponent } from "./user-filter-modal/user-filter.modal";
import { OrderRefundAllComponent } from './order-refund-all/order-refund-all.component';
import { TaskDeclineModalComponent } from './task-decline-modal/task-decline-modal.component';
import { ProductGalleryModal } from "./product-gallery-modal/product-gallery.modal";
import { BundleProductLineDetailsModalComponent } from "./bundle-product-line-details-modal/bundle-product-line-details.modal";
import { LotteryNumberModalComponent } from "./lottery-number-modal/lottery-number.modal";
import { GreetingCardAddModal } from "./greeting-card-add-modal/greeting-card-add.modal";
import { FiscalIdModalComponent } from "./fiscal-id-modal/fiscal-id.modal";
import { ExcludedProductsModalComponent } from "./excluded-products-modal/excluded-products.modal";
import { GenericOrderVerificationModalComponent } from "./generic-order-verification-modal/generic-order-verification-modal";
import { CustomerVerificationQRModalComponent } from "./customer-verification-qr-modal/customer-verification-qr.component";
import { CustomerVerificationPincodeModal } from "./customer-verification-pincode-modal/customer-verification-pincode";
import { ScannMultipleProductsModalComponent } from "./scann-products-modal/scann-products.modal";
import { ProductImageModal } from "./product-image-modal/product-image.modal";
import { ProductDamageImageModal } from "./product-damage-image-modal/product-damage-image-modal.modal";
import { TwoFactorAuthenticationPincodeModal } from "./two-factor-auth-pincode-modal/two-factor-auth-pincode";
import { StockReservationQuantityModal } from "./stock-reservation-quantity-modal/stock-reservation-quantity.modal";
import { CustomFieldsArraysModalComponent } from "./custom-fields-arrays-modal/custom-fields-arrays.modal";
import { RecipientFieldsModalComponent } from "./recipient-fields-modal/recipient-fields.modal";
import { OrderOptionReadModalComponent } from "./order-option-read-modal/order-option-read.modal";
import { GenericElevationCheckQRModal } from "./elevation-check-qr-modal/elevation-check-qr-modal";
import { GenericElevationCheckPincodeModal } from "./elevation-check-pincode-modal/elevation-check-pincode-modal";
import { UnitOrganizationSearchModal } from "./unit-organizations-search-modal/ou-search-modal";
import { FiscalRemarkModalComponent } from "./fiscal-remark-modal/fiscal-remark-modal";
import { SubscriptionInquiriesModalComponent } from "./subs-inquiries-modal/subs-inquiries-modal";
import { DeprecatedModalComponent } from "./deprecated-modal/deprecated-modal";

@NgModule({
  declarations: [
    CashDrawerModalComponent,
    CreateShipmentFeedbackModalComponent,
    CustomerReferenceModalComponent,
    CustomerSearchModalComponent,
    DeprecatedModalComponent,
    SelectStationModalComponent,
    GenerateXReportModalComponent,
    InsufficientStockModalComponent,
    ItemSearchComponent,
    EditPinModalComponent,
    OrderLineDeliveryInfoModalComponent,
    OrderRemarkModalComponent,
    OrderSetSoldByModalComponent,
    PhoneNumberModalComponent,
    ProductDiscountOptionsModalComponent,
    QuickBuyModalComponent,
    RefundTransactionModalComponent,
    RitualsPrintTalismanModalComponent,
    TaskFilterModalComponent,
    ConfigPickerModalComponent,
    RunnerTaskBlockedProductsModalComponent,
    SharePaymentModalComponent,
    ShippingRestrictionsModalComponent,
    TaxExemptionModalComponent,
    SummaryCheckoutModalComponent,
    VatNumberModalComponent,
    FiscalIdModalComponent,
    ErrorViewerModalComponent,
    ModifyPriceModalComponent,
    DeliveryRestrictionsModalComponent,
    PauseOrderModalComponent,
    ReturnOrdersModalComponent,
    BundleProductsModalComponent,
    ShippingMethodModalComponent,
    EvaOrderlineRequirementModalComponent,
    GiftCardModalComponent,
    PaymentTimeoutModalComponent,
    GiftReceiptModalComponent,
    BorrowedFilterModalComponent,
    ReturnDamagedModalComponent,
    UserFilterModalComponent,
    OrderRefundAllComponent,
    TaskDeclineModalComponent,
    ProductGalleryModal,
    BundleProductLineDetailsModalComponent,
    LotteryNumberModalComponent,
    GreetingCardAddModal,
    ExcludedProductsModalComponent,
    CustomerVerificationQRModalComponent,
    GenericOrderVerificationModalComponent,
    CustomerVerificationPincodeModal,
    ScannMultipleProductsModalComponent,
    ProductImageModal,
    ProductDamageImageModal,
    TwoFactorAuthenticationPincodeModal,
    StockReservationQuantityModal,
    CustomFieldsArraysModalComponent,
    RecipientFieldsModalComponent,
    OrderOptionReadModalComponent,
    GenericElevationCheckQRModal,
    GenericElevationCheckPincodeModal,
    UnitOrganizationSearchModal,
    FiscalRemarkModalComponent,
    SubscriptionInquiriesModalComponent,
  ],
  exports: [
    CashDrawerModalComponent,
    CreateShipmentFeedbackModalComponent,
    CustomerReferenceModalComponent,
    CustomerSearchModalComponent,
    DeprecatedModalComponent,
    SelectStationModalComponent,
    GenerateXReportModalComponent,
    InsufficientStockModalComponent,
    ItemSearchComponent,
    EditPinModalComponent,
    OrderLineDeliveryInfoModalComponent,
    OrderRemarkModalComponent,
    OrderSetSoldByModalComponent,
    PhoneNumberModalComponent,
    ProductDiscountOptionsModalComponent,
    QuickBuyModalComponent,
    RefundTransactionModalComponent,
    RitualsPrintTalismanModalComponent,
    TaskFilterModalComponent,
    ConfigPickerModalComponent,
    RunnerTaskBlockedProductsModalComponent,
    SharePaymentModalComponent,
    ShippingRestrictionsModalComponent,
    TaxExemptionModalComponent,
    SummaryCheckoutModalComponent,
    VatNumberModalComponent,
    FiscalIdModalComponent,
    ErrorViewerModalComponent,
    ModifyPriceModalComponent,
    DeliveryRestrictionsModalComponent,
    PauseOrderModalComponent,
    ReturnOrdersModalComponent,
    BundleProductsModalComponent,
    ShippingMethodModalComponent,
    EvaOrderlineRequirementModalComponent,
    GiftCardModalComponent,
    PaymentTimeoutModalComponent,
    GiftReceiptModalComponent,
    BorrowedFilterModalComponent,
    ReturnDamagedModalComponent,
    UserFilterModalComponent,
    OrderRefundAllComponent,
    TaskDeclineModalComponent,
    ProductGalleryModal,
    BundleProductLineDetailsModalComponent,
    LotteryNumberModalComponent,
    GreetingCardAddModal,
    ExcludedProductsModalComponent,
    CustomerVerificationQRModalComponent,
    GenericOrderVerificationModalComponent,
    CustomerVerificationPincodeModal,
    ScannMultipleProductsModalComponent,
    ProductImageModal,
    ProductDamageImageModal,
    TwoFactorAuthenticationPincodeModal,
    StockReservationQuantityModal,
    CustomFieldsArraysModalComponent,
    RecipientFieldsModalComponent,
    OrderOptionReadModalComponent,
    GenericElevationCheckQRModal,
    GenericElevationCheckPincodeModal,
    UnitOrganizationSearchModal,
    FiscalRemarkModalComponent,
    SubscriptionInquiriesModalComponent,
  ],
  entryComponents: [
    CashDrawerModalComponent,
    CreateShipmentFeedbackModalComponent,
    CustomerReferenceModalComponent,
    CustomerSearchModalComponent,
    DeprecatedModalComponent,
    SelectStationModalComponent,
    GenerateXReportModalComponent,
    InsufficientStockModalComponent,
    ItemSearchComponent,
    EditPinModalComponent,
    OrderLineDeliveryInfoModalComponent,
    OrderRemarkModalComponent,
    OrderSetSoldByModalComponent,
    PhoneNumberModalComponent,
    ProductDiscountOptionsModalComponent,
    QuickBuyModalComponent,
    RefundTransactionModalComponent,
    RitualsPrintTalismanModalComponent,
    TaskFilterModalComponent,
    ConfigPickerModalComponent,
    RunnerTaskBlockedProductsModalComponent,
    SharePaymentModalComponent,
    ShippingRestrictionsModalComponent,
    TaxExemptionModalComponent,
    SummaryCheckoutModalComponent,
    VatNumberModalComponent,
    FiscalIdModalComponent,
    ErrorViewerModalComponent,
    ModifyPriceModalComponent,
    DeliveryRestrictionsModalComponent,
    PauseOrderModalComponent,
    ReturnOrdersModalComponent,
    BundleProductsModalComponent,
    ShippingMethodModalComponent,
    EvaOrderlineRequirementModalComponent,
    GiftCardModalComponent,
    PaymentTimeoutModalComponent,
    GiftReceiptModalComponent,
    BorrowedFilterModalComponent,
    ReturnDamagedModalComponent,
    UserFilterModalComponent,
    OrderRefundAllComponent,
    TaskDeclineModalComponent,
    ProductGalleryModal,
    BundleProductLineDetailsModalComponent,
    LotteryNumberModalComponent,
    GreetingCardAddModal,
    ExcludedProductsModalComponent,
    CustomerVerificationQRModalComponent,
    GenericOrderVerificationModalComponent,
    CustomerVerificationPincodeModal,
    ScannMultipleProductsModalComponent,
    ProductImageModal,
    ProductDamageImageModal,
    TwoFactorAuthenticationPincodeModal,
    StockReservationQuantityModal,
    CustomFieldsArraysModalComponent,
    RecipientFieldsModalComponent,
    OrderOptionReadModalComponent,
    GenericElevationCheckQRModal,
    GenericElevationCheckPincodeModal,
    UnitOrganizationSearchModal,
    FiscalRemarkModalComponent,
    SubscriptionInquiriesModalComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FusejsModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    ValMessagesModule,
    IonInputFocusFixModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalsModule {}
