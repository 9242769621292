import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { getCurrentUser } from "@springtree/eva-sdk-redux";
import { isArray, isEmpty, isNil } from "lodash-es";
import { first } from "rxjs/operators";
import { ILoggable, Logger } from "src/app/shared/decorators/logger";
import { EvaApplicationConfigProvider } from "../eva-application-config/eva-application-config";
import moment from "moment";

/**
 * This contains a list of rituals backend ids we will pay attention to
 * @see https://n6k.atlassian.net/browse/OPTR-15518?focusedCommentId=86514
 */
type TRitualsCustomFieldBackendId = 'RGC_IsEmployee' | 'RGC_EmployeeRemainingDiscount' | 'RGC_LoyaltyID' | 'RGC_CanRedeemWelcomeGift';

interface IGetRitualCustomFieldsOpts<T> {
  customFields: EVA.Core.CustomFieldResponse[];
  fieldName: TRitualsCustomFieldBackendId;
  defaultValue: T;
}

/** This will act as an object that closely resembles EVA.Core.CustomFieldKeyValue, to ensure we can get some consistency between listCustomFields&getUser across our project */
export interface CustomFieldFormConfig {
  Name: string;
  IsArray?: boolean;
  Options?: EVA.Core.CustomFieldOptions;
  DataTypeID: EVA.Core.CustomFieldDataTypes;
  DisplayName?: string;
  CustomFieldID: number;
  BackendID?: string;
  EnumValues?: { [key: string]: string };
}

// Instead of using EVA.Core.CustomFieldKeyValue directly, we will use this instead as it is more convenient to use
// reason being custom fields data structure is different depending on where you retrieve it from. The following two services return different structures
// - listCustomFields
// - getUser
//
interface SetCustomerUserDataCustomFieldValue extends CustomFieldFormConfig {
  Value: any;
}
export interface SetCustomerUserDataOpts {
  customFieldConfigs: CustomFieldFormConfig[];
  // Key here is the customFieldId and value is the value
  //
  customFieldFormValue: {[key: string]: any};
}

export interface CustomerUpsertView {
  customFields: CustomFieldFormConfig[];
  customFieldsFormGroup: FormGroup;
}

/**
 * This service will be responsible for working with custom fields by doing
 * - interpertation of the user custom fields object (For example, given a userDto it will be able to tell you whether a user is a rituals employee or not)
 * - retrieving the list of custom fields
 * - setting the value of a custom field on a user object
 * @see https://n6k.atlassian.net/browse/OPTR-15518
 */
@Logger('[user-custom-fields-service]')
@Injectable({
  providedIn: 'root'
})
export class UserCustomFieldsService implements ILoggable {
  logger: Partial<Console>;

  constructor(private fb: FormBuilder, private $evaApplicationConfig: EvaApplicationConfigProvider) {}

  isRitualsEmployee(customFields: EVA.Core.CustomFieldResponse[]) {
    return this.getCustomField({
      customFields,
      defaultValue: false,
      fieldName: 'RGC_IsEmployee'
    });
  }

  getRitualsLoyaltyId(customFields: EVA.Core.CustomFieldResponse[]): string {
    return this.getCustomField<string|null>({
      customFields,
      defaultValue: null,
      fieldName: 'RGC_LoyaltyID'
    });
  }

  isRitualsLoyaltyEnabled(customFields: EVA.Core.CustomFieldResponse[]): boolean {
    const loyaltyId = this.getCustomField<string|null>({
      customFields,
      defaultValue: null,
      fieldName: 'RGC_LoyaltyID'
    });

    const loyaltyEnabled = !isNil(loyaltyId);

    return loyaltyEnabled;
  }

  getRitualsAvailableDiscount(customFields: EVA.Core.CustomFieldResponse[]) {
    return this.getCustomField({
      customFields,
      defaultValue: 0,
      fieldName: 'RGC_EmployeeRemainingDiscount'
    });
  }

  getRitualsCanRedeemWelcomeGift(customFields: EVA.Core.CustomFieldResponse[]) {
    return this.getCustomField({
      customFields,
      defaultValue: false,
      fieldName: 'RGC_CanRedeemWelcomeGift'
    });
  }

  /** Will return the CustomFields if there are any values in the provided form value key-value pair structure */
  getCustomUserData(opts: SetCustomerUserDataOpts) {
    /** We want to pluck out the entries that have a non value, as those are the ones the user filled in */
    const customFieldsFilledEntries = Object.entries(opts.customFieldFormValue).filter(([_key, value]) => {
      return value !== null;
    });

    // If there are no entries, we are simply returning early with a resolved promise
    //
    if (isEmpty(customFieldsFilledEntries)) {
      return [];
    }

    const customFieldValues: SetCustomerUserDataCustomFieldValue[] = customFieldsFilledEntries.map(([customFieldId, value]) => {
      const matchingCustomField = opts.customFieldConfigs.find(customField => customField.CustomFieldID === parseInt(customFieldId));

      return { ...matchingCustomField, Value: value };
    });

    const customFields: EVA.Core.CustomFieldKeyValue[] = customFieldValues.map( customFieldValue => {
      const customField: EVA.Core.CustomFieldKeyValue = {
        ArrayValues: undefined,
        CustomFieldID: customFieldValue.CustomFieldID,
        BoolValue: undefined,
        DateTimeValue: undefined,
        NumberValue: undefined,
        StringValue: undefined,
      };

      if ( isArray(customFieldValue.Value) ) {
        customField.ArrayValues = (customFieldValue.Value || []).map(value => {
          const dataType = this.getDataType(customFieldValue.DataTypeID);
          if (value[dataType]) {
            return value;
          }

          return { [dataType]: value };
        });
      } else if (customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.Bool) {
        customField.BoolValue = customFieldValue.Value;
      } else if (customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.DateTime) {
        customField.DateTimeValue = !isNil(customFieldValue.DataTypeID) ? this.formatDateOut(customFieldValue.Value) : '';
      } else if (customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.Date) {
        // this format is accepted on BE for Date type: YYYY-MM-DD
        //
        customField.DateTimeValue = !isNil(customFieldValue.DataTypeID) ? customFieldValue.Value.split('T')[0] : '';
      } else if (customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.Integer || customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.Decimal) {
        customField.NumberValue = customFieldValue.Value;
      } else if (customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.String || customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.Enum || customFieldValue.DataTypeID === EVA.Core.CustomFieldDataTypes.Text) {
        customField.StringValue = customFieldValue.Value;
      }


      return customField;
    });

    return customFields;
  }

  private getDataType(dataTypeId: EVA.Core.CustomFieldDataTypes): string {
    let dataType = '';
    switch(dataTypeId) {
      case EVA.Core.CustomFieldDataTypes.Bool:
        dataType = 'BoolValue';
        break;
      case EVA.Core.CustomFieldDataTypes.DateTime:
      case EVA.Core.CustomFieldDataTypes.Date:
        dataType = 'DateTimeValue';
        break;
      case EVA.Core.CustomFieldDataTypes.Integer:
      case EVA.Core.CustomFieldDataTypes.Decimal:
        dataType = 'NumberValue';
        break;
      case EVA.Core.CustomFieldDataTypes.String:
      case EVA.Core.CustomFieldDataTypes.Enum:
      case EVA.Core.CustomFieldDataTypes.Text:
        dataType = 'StringValue';
        break;
    }

    return dataType;
  }

  private formatDateOut(date: string): string {
    const isoString = moment(date).format();
    const dateWithoutTimezone = isoString.split('+')[0];

    return dateWithoutTimezone;
  }

  private formatDateIn(date: string): string {
    const dateWithoutTimezone = date.split('Z')[0];

    return dateWithoutTimezone;
  }

  /** Will return all the custom fields needed for editing and creating customers */
  async getCustomerUpsertView(userCurrentCustomFields: { [key: number]: EVA.Core.CustomFieldValueWithOptions }): Promise<CustomerUpsertView> {
    let customFields = (await this.fetchCustomFields() ?? []);

    const { User } = await getCurrentUser.getResponse$().pipe(first()).toPromise();

    const customFieldsFormGroup = this.fb.group({});

    // hide the fields if they are not editable and have not a DefaultValue
    // only for creating user
    //

    customFields = customFields.filter(customField => {
      const isEditable = this.isEditableCustomField(customField, User);
      const noDefaultValue = isNil(customField.Options?.DefaultValue);

      return !(noDefaultValue && !isEditable && isNil(userCurrentCustomFields));
    });

    const userCurrentCustomFieldsIds = Object.keys(userCurrentCustomFields ?? {}).map(Number);
    userCurrentCustomFieldsIds.forEach(customFieldId => {
      // If we have a matching value, which we could have in the edit flow, we will use it.
      //
      const matchingCustomField = (customFields ?? []).find(customFieldValue => customFieldValue.CustomFieldID === customFieldId);
      const matchingValueType = userCurrentCustomFields?.[customFieldId].Value;
      let matchingValue;
      if (!isNil(matchingValueType)) {
        switch (matchingCustomField.DataTypeID) {
          case EVA.Core.CustomFieldDataTypes.Text:
          case EVA.Core.CustomFieldDataTypes.String:
            matchingValue = !matchingCustomField.IsArray ?
              matchingValueType.StringValue :
              matchingValueType.ArrayValues?.map(value => value.StringValue);
            break;
          case EVA.Core.CustomFieldDataTypes.Enum:
            matchingValue = matchingValueType.StringValue || matchingValueType.ArrayValues?.map(value => value.StringValue);
            break;
          case EVA.Core.CustomFieldDataTypes.DateTime:
          case EVA.Core.CustomFieldDataTypes.Date:
            matchingValue = this.formatDateIn(matchingValueType.DateTimeValue);
            break;
          case EVA.Core.CustomFieldDataTypes.Integer:
          case EVA.Core.CustomFieldDataTypes.Decimal:
            matchingValue = !matchingCustomField.IsArray ?
              matchingValueType.NumberValue :
              matchingValueType.ArrayValues?.map(value => value.NumberValue);
            break;
          case EVA.Core.CustomFieldDataTypes.Bool:
            matchingValue = matchingValueType.BoolValue;
            break;
        }
      }
      customFieldsFormGroup.addControl(matchingCustomField.CustomFieldID.toString(), this.fb.control(matchingValue));

      const currentCustomFieldControl = customFieldsFormGroup.get(matchingCustomField.CustomFieldID.toString());
      const defaultValue = matchingCustomField.Options?.DefaultCustomFieldValue;

      /**
       * TODO: temporary implementation until we have a BE results
       * these values are only set for creating customer
       */
      if (isNil(matchingValue) && !isNil(defaultValue)) {
        switch (matchingCustomField.DataTypeID) {
          case EVA.Core.CustomFieldDataTypes.Text:
          case EVA.Core.CustomFieldDataTypes.String:
            !matchingCustomField.IsArray ?
              currentCustomFieldControl.setValue(defaultValue.StringValue) :
              currentCustomFieldControl.setValue(defaultValue.ArrayValues?.map(value => value.StringValue));
            break;
          case EVA.Core.CustomFieldDataTypes.Enum:
            currentCustomFieldControl.setValue(defaultValue.StringValue || defaultValue.ArrayValues?.map(value => value.StringValue));
            break;
          case EVA.Core.CustomFieldDataTypes.DateTime:
          case EVA.Core.CustomFieldDataTypes.Date:
            currentCustomFieldControl.setValue(defaultValue.DateTimeValue);
            break;
          case EVA.Core.CustomFieldDataTypes.Integer:
          case EVA.Core.CustomFieldDataTypes.Decimal:
            !matchingCustomField.IsArray ?
              currentCustomFieldControl.setValue(defaultValue.NumberValue) :
              currentCustomFieldControl.setValue(defaultValue.ArrayValues?.map(value => value.NumberValue));
            break;
          case EVA.Core.CustomFieldDataTypes.Bool:
            currentCustomFieldControl.setValue(defaultValue.BoolValue);
            break;
        }
      }

      // disable the formControl when the field is not editable
      //
      const isEditable = this.isEditableCustomField(matchingCustomField, User);
      if (!isEditable) {
        currentCustomFieldControl.disable();
      }
    });

    const customerUpsertView: CustomerUpsertView = {
      customFields,
      customFieldsFormGroup
    };

    return customerUpsertView
  }

  /**
   * If the custom field does not have a EditableByUserTypes property, it is editable by all users
   * if the property is present, we want to compare it to the current user to see if they're allowed to edit
   */
  private isEditableCustomField(customField: EVA.Core.CustomFieldResponse, user: EVA.Core.LoggedInUserDto) {
    return isNil(customField.IsEditableByUser) || Boolean(user.Type & Number(customField.IsEditableByUser));
  }

  /**
   * Will fetch the custom fields that are used to show the employee custom fields that are configured by our customers
   */
  private async fetchCustomFields(): Promise<EVA.Core.CustomFieldResponse[]> {
    try {
      const extendedCustomFields = await this.$evaApplicationConfig.extendedCustomFields$.pipe(first()).toPromise();
      const userCustomFields = extendedCustomFields['User'] ?? [];

      return userCustomFields;

    } catch (error) {
      this.logger.error('error fetching custom fields', error);
    }
  }

  private getCustomField<T>(opts: IGetRitualCustomFieldsOpts<T>): T {
    const field = (opts.customFields ?? []).find( field => field.BackendID === opts.fieldName);

    const fieldValue = isNil(field) || isNil(field.Value) ? opts.defaultValue : field.Value as T;

    return fieldValue;
  }
}
