<ion-grid class="ion-no-padding">

  <!-- SubTotal section -->
  <ion-row class="ion-justify-content-between ion-align-items-center">
    <span>{{ 'total.products' | translate }} ({{ orderData?.totalItems }})</span>
    <span class="price">
      <ion-row class="ion-align-items-center">
        <eva-localized-price
          [currencyString]="orderData?.currency"
          [pricing]="subTotalPricing"
          [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
        </eva-localized-price>
        <ion-button fill="clear" size="small" secondary-text class="ion-no-margin toggle-button" (click)="orderLinesCollapsed = !orderLinesCollapsed">
          <ion-icon *ngIf="!orderLinesCollapsed" name="caret-up"></ion-icon>
          <ion-icon *ngIf="orderLinesCollapsed" name="caret-down"></ion-icon>
        </ion-button>
      </ion-row>
    </span>
  </ion-row>

  <div @slideUpDown *ngIf="!orderLinesCollapsed" class="lines-container">
    <!-- Display the order lines with subtotals -->
    <hr>

    <ng-container *ngIf="!showOrderLinesTypes">
      <eva-checkout-total-line *ngFor="let productLine of productLines"
        [productLine]="productLine"
        [orderData]="orderData">
      </eva-checkout-total-line>
    </ng-container>

    <!-- SERVICE TYPE -->
    <ng-container *ngIf="showOrderLinesTypes && productLinesServiceType?.length">
      <h6 class="order-line-type-title">{{ 'order.type.service' | translate }}</h6>
      <eva-checkout-total-line *ngFor="let productLine of productLinesServiceType"
        [productLine]="productLine"
        [orderData]="orderData" [showLineTypeView]="true">
      </eva-checkout-total-line>
    </ng-container>

    <!-- RESERVE TYPE -->
    <ng-container *ngIf="showOrderLinesTypes && productLinesReserveType?.length">
      <h6 class="order-line-type-title">{{ 'order.type.reserve' | translate }}</h6>
      <eva-checkout-total-line *ngFor="let productLine of productLinesReserveType"
        [productLine]="productLine"
        [orderData]="orderData" [showLineTypeView]="true">
      </eva-checkout-total-line>
    </ng-container>

    <!-- ORDER TYPE -->
    <ng-container *ngIf="showOrderLinesTypes && productLinesOrderLineType?.length">
      <h6 class="order-line-type-title">{{ 'order.type.order' | translate }}</h6>
      <eva-checkout-total-line *ngFor="let productLine of productLinesOrderLineType"
        [productLine]="productLine"
        [orderData]="orderData" [showLineTypeView]="true">
      </eva-checkout-total-line>
    </ng-container>

    <!-- CARRY-OUT TYPE -->
    <ng-container *ngIf="showOrderLinesTypes && productLinesCarryOutType?.length">
      <h6 class="order-line-type-title">{{ 'order.type.shipline' | translate }}</h6>
      <eva-checkout-total-line *ngFor="let productLine of productLinesCarryOutType"
        [productLine]="productLine"
        [orderData]="orderData" [showLineTypeView]="true">
      </eva-checkout-total-line>
    </ng-container>

    <!-- DELIVERY TYPE -->
    <ng-container *ngIf="showOrderLinesTypes && productLinesDeliveryType?.length">
      <h6 class="order-line-type-title">{{ 'order.type.delivery' | translate }}</h6>
      <eva-checkout-total-line *ngFor="let productLine of productLinesDeliveryType"
        [productLine]="productLine"
        [orderData]="orderData" [showLineTypeView]="true">
      </eva-checkout-total-line>
    </ng-container>

     <!-- SHIPPED LINES -->
     <ng-container *ngIf="showOrderLinesTypes && productLinesShippedType?.length">
      <h6 class="order-line-type-title">{{ 'order.type.shipped' | translate }}</h6>
      <eva-checkout-total-line *ngFor="let productLine of productLinesShippedType"
        [productLine]="productLine" [showShippedQuantity]="true"
        [orderData]="orderData" [showLineTypeView]="true">
      </eva-checkout-total-line>
    </ng-container>

  </div>
  <ng-container *ngIf="containsAmountsTypes">
    <hr>
    <ion-row *ngIf="!( orderData?.amounts?.Types.Discount?.InTax | isNil )" class="ion-justify-content-between">
      <span class="light-text"><span secondary-text>{{ 'total.discount' | translate }}</span></span>
      <span class="light-text price">
        <eva-price [currencyString]="orderData?.currency" secondary-text [price]="orderData?.amounts?.Types?.Discount?.InTax">
        </eva-price>
      </span>
    </ion-row>
    <ion-row *ngIf="!( orderData?.amounts?.Types.ShippingCosts?.InTax | isNil )" class="ion-justify-content-between">
      <span class="light-text">{{ 'total.shipping.costs' | translate }}</span>
      <span class="light-text price">
        <eva-price [currencyString]="orderData?.currency" [price]="orderData?.amounts?.Types?.ShippingCosts?.InTax" class="ion-justify-content-between">
        </eva-price>
      </span>
    </ion-row>
    <ion-row *ngIf="!( orderData?.amounts?.Types.ReturnCosts?.InTax | isNil )" class="ion-justify-content-between">
      <span class="light-text">{{ 'total.return.costs' | translate }}</span>
      <span class="light-text price">
        <eva-price [currencyString]="orderData?.currency" [price]="orderData?.amounts?.Types?.ReturnCosts?.InTax">
        </eva-price>
      </span>
    </ion-row>
    <ion-row *ngIf="!( orderData?.amounts?.Types.ExtraCosts?.InTax | isNil )" class="ion-justify-content-between">
      <span class="light-text">{{ 'total.extra.costs' | translate }}</span>
      <span class="light-text price">
        <eva-price [currencyString]="orderData?.currency" [price]="orderData?.amounts?.Types?.ExtraCosts?.InTax">
        </eva-price>
      </span>
    </ion-row>
  </ng-container>

  <ng-container *ngIf="
    ((showGiftwrapping$ | async) ||
    (hasGreetingCard$ | async) ||
    giftwrappingCosts.price > 0) &&
    giftwrappingCosts.price > 0
  ">
      <ion-row class="ion-justify-content-between">
          <span class="light-text">{{ 'gift.wrapping.title' | translate }}</span>
          <span class="light-text price">
              <eva-localized-price
                [pricing]="giftwrappingCosts"
                [currencyString]="orderData?.currency"
                [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
              </eva-localized-price>
          </span>
      </ion-row>
      <div *ngIf="hasGreetingCard$ | async">
      <ion-row class="ion-margin-start ion-justify-content-between">
        <span class="light-text">{{ 'greeting.card.title' | translate }}</span>
    </ion-row>
</div>
  </ng-container>

  <ng-container *ngIf="!(orderData?.amounts?.Taxes | isEmpty)">
    <hr>
    <ion-row *ngFor="let tax of orderData?.amounts?.Taxes" class="ion-justify-content-between">
      <span class="light-text">
        <ng-container *ngIf="checkoutPageActive && (preferredPriceDisplayMode$ | async); else includedTax">
          <span>{{ 'vat' | translate }}</span> - {{ tax.Rate - 1 | intlNumber: intlNumberTaxOpts }}
        </ng-container>
        <ng-template #includedTax>
          <span>{{ 'vat.included' | translate }}</span> - {{ tax.Rate - 1 | intlNumber: intlNumberTaxOpts }}
        </ng-template>
      </span>

      <span class="light-text price">
        <eva-price [currencyString]="orderData?.currency" [price]="tax.Amount"></eva-price>
      </span>
    </ion-row>
  </ng-container>
  <hr>
  <ion-row class="ion-justify-content-between"
    [ngClass]="{ 'open-amount': (isRefundAdyenDropin ? orderData?.amounts?.Open.PendingInTax : orderData?.amounts?.Open.InTax) === orderData?.amounts?.Total.InTax }">
    <span>{{ 'total.amount' | translate }}</span>
    <span class="price">
      <eva-price
        [currencyString]="orderData?.currency"
        [price]="orderData?.amounts?.Total.InTax || 0">
      </eva-price>
    </span>
  </ion-row>
  <ion-row class="ion-justify-content-between"
    [ngClass]="{ 'open-amount': (isRefundAdyenDropin ? orderData?.amounts?.Open.PendingInTax : orderData?.amounts?.Open.InTax) !== orderData?.amounts?.Total.InTax }"
    *ngIf="(isRefundAdyenDropin ? orderData?.amounts?.Open.PendingInTax : orderData?.amounts?.Open.InTax) !== orderData?.amounts?.Total.InTax">
    <span><strong>{{ 'total.open' | translate }}</strong></span>
    <span class="price">
      <eva-price
        [currencyString]="orderData?.currency"
        [price]="(isRefundAdyenDropin ? orderData?.amounts?.Open.PendingInTax : orderData?.amounts?.Open.InTax) || 0">
      </eva-price>
    </span>
  </ion-row>
  <ion-row *ngIf="(ecoTaxesOnCart$ | async) || (ecoTaxesOnOrder$ | async) as ecoTaxes" class="ion-justify-content-end eco-tax">
    <eva-eco-tax-label
      [currencyID]="orderData?.currency"
      [ecoTaxInTax]="ecoTaxes?.EcoTaxInTax" [showTotal]="false" [showItem]="false"
      [preferredPriceDisplayMode]="preferredPriceDisplayMode$ | async">
    </eva-eco-tax-label>
  </ion-row>
</ion-grid>
