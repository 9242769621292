import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'deprecated-modal',
  templateUrl: 'deprecated-modal.html',
  styleUrls: ['./deprecated-modal.scss']
})
export class DeprecatedModalComponent {

  constructor(private modalCtrl: ModalController) {}

  public dismiss() {
    this.modalCtrl.dismiss();
  }
}
